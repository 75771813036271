import React from "react";
import {BrowserRouter, Routes, Route } from "react-router-dom";
// import ProtectedRoute from "routes/protectedRoute";
// import Home from "components/Home/Home";
// import Development from "components/Development/Development";
// import Builders from "components/Builders/Builders";
// import PropertyManagement from "components/PropertyManagement/PropertyManagement";
// import About from "components/About/About";
// import Careers from "components/Careers/Careers";
// import Contact from "components/Contact/Contact";
// import PropertyList from "components/Development/PropertyList/PropertyList";
// import PropertyDetails from "components/Development/PropertyDetails/PropertyDetails";
// import ProjectList from "components/Builders/ProjectList/ProjectList";
// import ProjectDetails from "components/Builders/ProjectDetails/ProjectDetails";
// import RentalProperty from "components/PropertyManagement/RentalProperty/RentalProperty";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";

import PropertyList1 from "new_components/Development/PropertyList/PropertyList";
import PropertyDetails1 from "new_components/Development/PropertyDetails/PropertyDetails";
import Home1 from "new_components/Home/Home";
import Contactus1 from "new_components/ContactUs/ContactUs";
import Careers1 from "new_components/Careers/Careers";
import Development1 from "new_components/Development/Development";
import About1 from "new_components/AboutUs/AboutUs";
import Builders1 from "new_components/Builders/Builders";
import OngoingProjects from "new_components/Builders/OngoingProjects";
import CompletedProjects from "new_components/Builders/CompletedProjects";
import ClosedInvestors from "new_components/Development/ClosedInvestors";
import OpenedInvestors from "new_components/Development/OpenInvestors";
import PrivacyPolicy from "new_components/PrivacyPolicy/PrivacyPolicy";

const AppRoutes = () => (
  
    <Routes>
      {/* <Route path="/" element={<Home />} />

      <Route path="/development" element={<Development />} />

      <Route path="/builders" element={<Builders />} />

      <Route path="/property-management" element={<PropertyManagement />} />

      <Route path="/about-us" element={<About />} />

      <Route path="/careers" element={<Careers />} />

      <Route path="/contact-us" element={<Contact />} /> */}

      <Route path="/" element={<Home1 />} />
      <Route path="/property-list" element={<PropertyList1 />} />
      <Route path="/property-details" element={<PropertyDetails1 />} />
      <Route path="/contact-us" element={<Contactus1/>}/>
      <Route path="/careers" element={<Careers1 />} />
      <Route path="/services/development" element={<Development1 />} />
      <Route path="/about-us" element={<About1 />} />
      <Route path="/services/builders" element={<Builders1 />} />
      <Route path="/services/builders/ongoing-projects" element={<OngoingProjects />} />
      <Route path="/services/builders/completed-projects" element={<CompletedProjects />} />
      <Route path="/services/development/closed-investors" element={<ClosedInvestors />}/>
      <Route path="/services/development/open-investors" element={<OpenedInvestors />}/>
      <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
      <Route path="*" element={<ErrorBoundary />}/>
      {/* <Route
        path="/development/property-list"
        element={
          <ProtectedRoute path={"development"}>
            <PropertyList />
          </ProtectedRoute>
        }
      />

      <Route
        path="/development/property-list/property-details/:id"
        element={
          <ProtectedRoute path={"development"}>
            <PropertyDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path="/builders/property-list"
        element={
          <ProtectedRoute path={"builders"}>
            <ProjectList />
          </ProtectedRoute>
        }
      />

      <Route
        path="/builders/property-list/property-details/:id"
        element={
          <ProtectedRoute path={"builders"}>
            <ProjectDetails />
          </ProtectedRoute>
        }
      />

      <Route
        path="/property-management/rental-property"
        element={<RentalProperty />}
      /> */}
    </Routes>
  

);

export default AppRoutes;
