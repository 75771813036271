import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import logoGroup from "assets/images/Headers/logogroup.svg";
import "new_assets/css/styles.css";
import "new_components/Header/Header.scss";

const Header = () => {
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const isServicesActive = [
    "/services/development",
    "/services/builders",
  ].includes(location.pathname);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    if (!isMobileMenuOpen) {
      document.body.classList.add("mobile-menu-visible");
    } else {
      document.body.classList.remove("mobile-menu-visible");
    }
  };

  return (
    <div className="main-header fixed-header">
      <div className="header-lower">
        <div className="row">
          <div className="col-lg-12">
            <div className="inner-header">
              <div className="inner-header-left">
                <div className="logo-box flex">
                  <div className="logo">
                    <NavLink to="/">
                      <img src={logoGroup} alt="logo" />
                    </NavLink>
                  </div>
                </div>
                <div className="nav-outer flex align-center ml-auto">
                  <nav className="main-menu show navbar-expand-md">
                    <div
                      className="navbar-collapse collapse clearfix"
                      id="navbarSupportedContent"
                    >
                      <ul className="navigation clearfix">
                        <li
                          className={`dropdown2 home ${
                            location.pathname === "/" ? "active" : ""
                          }`}
                        >
                          <NavLink className="nav-link" to="/">
                            Home
                          </NavLink>
                        </li>
                        <li
                          className={`dropdown2 services ${
                            isServicesActive ? "active" : ""
                          }`}
                        >
                          <a className="nav-link">Services</a>
                          <ul>
                            <li
                              className={
                                location.pathname === "/services/development"
                                  ? "active"
                                  : ""
                              }
                            >
                              <NavLink to="/services/development">
                                Development
                              </NavLink>
                            </li>
                            <li
                              className={
                                location.pathname === "/services/builders"
                                  ? "active"
                                  : ""
                              }
                            >
                              <NavLink to="/services/builders">
                                Builders
                              </NavLink>
                            </li>
                          </ul>
                        </li>

                        <li
                          className={`dropdown2 about-us ${
                            location.pathname === "/about-us" ? "active" : ""
                          }`}
                        >
                          <NavLink className="nav-link" to="/about-us">
                            About Us
                          </NavLink>
                        </li>
                        <li
                          className={`dropdown2 careers ${
                            location.pathname === "/careers" ? "active" : ""
                          }`}
                        >
                          <NavLink className="nav-link" to="/careers">
                            Careers
                          </NavLink>
                        </li>
                        <li
                          className={`dropdown2 contact-us ${
                            location.pathname === "/contact-us" ? "active" : ""
                          }`}
                        >
                          <NavLink className="nav-link" to="/contact-us">
                            Contact Us
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
              <div
                className="mobile-nav-toggler mobile-button"
                onClick={toggleMobileMenu}
              >
                <span>☰</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="close-btn" onClick={toggleMobileMenu}>
        <span class="icon flaticon-cancel-1"></span>
      </div>
      <div className="mobile-menu">
        <div className="menu-backdrop" onClick={toggleMobileMenu}></div>
        <nav className="menu-box">
          <div className="nav-logo">
            <a href="index.html">
              <img src={logoGroup} alt="nav-logo" width={174} height={44} />
            </a>
          </div>
          <BottomCanvas />
        </nav>
      </div>
    </div>
  );
};

const BottomCanvas = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  return (
    <div className="bottom-canvas">
      <div className="menu-outer">
        <div
          className="navbar-collapse collapse clearfix"
          id="navbarSupportedContent"
        >
          <ul className="navigation clearfix">
            <li className="dropdown2">
              <a href="/">Home</a>
            </li>
            <li
              className={`dropdown2 ${isDropdownOpen ? "open" : ""}`}
              onClick={toggleDropdown}
            >
              <a href="#">Services</a>
              <ul style={{ display: isDropdownOpen ? "block" : "none" }}>
                <li>
                  <a href="/services/development">Development</a>
                </li>
                <li>
                  <a href="/services/builders">Builder</a>
                </li>
              </ul>
              <div className="dropdown2-btn"></div>
            </li>
            <li className="dropdown2">
              <a href="/about-us">About Us</a>
            </li>
            <li className="dropdown2">
              <a href="/careers">Careers</a>
            </li>
            <li className="dropdown2">
              <a href="/contact-us">Contact Us</a>
            </li>
          </ul>
        </div>
      </div>

      <div className="mobi-icon-box">
        <div className="box d-flex align-items-center">
          <span className="icon icon-phone2"></span>
          <div>239-387-2171</div>
        </div>
        <div className="box d-flex align-items-center">
          <span className="icon icon-mail"></span>
          <div>
            <a href="mailto:info@luckyduckgroup.com">info@luckyduckgroup.com</a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
