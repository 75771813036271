import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "new_components/Development/Development.scss";
import Header from "new_components/Header/Header";
import Footer from "new_components/Footer/Footer";
// import devInvest from "new_assets/new_images/Development/devInvest.png";
import devInvest from "new_assets/new_images/Development/devInvest2.jpg";
// import imagesss from "new_assets/new_images/Home/img-w-text-sm1.jpg";
// import clinv1 from "new_assets/new_images/Development/image (2).jpg";
// import clinv2 from "new_assets/new_images/Development/image (9).png";
// import opinv1 from "new_assets/new_images/Development/image2.png";
// import opinv2 from "new_assets/new_images/Development/image1.png";
import axios from "axios";
import { BASE_URL } from "new_components/BaseApi/BaseApi.js";
import ClosedInvestors from "./ClosedInvestors";
import ScrollToTopDefault from "components/Layouts/LayoutPrimary/ScrollToTopDefault";
// import OngoingProjects from "new_components/Builders/OngoingProjects";
// import ComingSoon from "new_assets/new_images/Development/image1.png";
import ComingSoon from "new_assets/new_images/Builders/coming_soon.png";
import { Image_Base_URL } from "new_components/BaseApi/BaseApi.js";
import ProgressCircle from "new_components/ScrollProgress/ScrollProgress";
import PreLoad from "new_components/PreLoad/PreLoad";
const Development = () => {
  return (
    <>
      <ScrollToTopDefault />
      <ProgressCircle />
      <Header />
      <Body />
      <Footer />
    </>
  );
};
const Body = () => {
  return (
    <div>
      <DevelopmentBanner />
      {/* <section className="flat-section">
        <div className="container">
          <div
            className="row align-items-center justify-content-center wow fadeInUp developmentbanner mx-md-5 px-1 px-md-5"
            data-wow-delay=".2s"
          >
            <div className="col-12 text-center">
              <p className="subtitle text-white body-2 wow fadeInUp">
                Welcome to
              </p>

              <h3 className="title text-white text-uppercase">
                LUCKY DUCK DEVELOPMENT
              </h3>
            </div>
            <div className="col-12 align-items-left">
              <p className="subtitle text-white body-2 wow fadeInUp px-5">
                Lucky Duck Development is a real estate investment company
                specializing in the development of premium waterfront properties
                in Southwest Florida. We acquire unique and aspirational
                properties and transform them into high value opportunities. Our
                expertise in the complete acquire-develop-sell value chain
                allows us to maximize your return potential while making lasting
                contributions to the vibrant Southwest Florida community.
              </p>
              <br></br>
              <p className="subtitle text-white body-2 wow fadeInUp px-5">
                All principles of Lucky Duck Development are not only the
                developers of each opportunity, but we also are investors on
                every project.
              </p>

              <br></br>
              <p className="subtitle text-white body-2 wow fadeInUp px-5">
                Invest with purpose. Let’s build our future together.
              </p>
              <br></br>
              <button
                className="tf-btn btn-view primary hover-btn-view ml-5"
                onClick={() => {
                  const url = new URL("/contact-us", window.location.origin);

                  window.location.href = url.toString();
                }}
              >
                Contact us<span className="icon icon-arrow-right2"></span>
              </button>
            </div>
          </div>
        </div>
      </section> */}
      {/* investment strategy */}
      <InvestmentStrategy />
      {/* <section className="flat-section bg-primary-new">
        <div className="container">
          <div className="box-title text-center wow fadeInUp">
            <h3 className="mt-4 title">INVESTMENT STRATEGY</h3>
          </div>
          <div
            className="row align-items-center justify-content-center wow fadeInUp"
            data-wow-delay=".2s"
          >
            <div className="col-12 col-md-4 text-center mb-2 dev-invest">
              <img src={devInvest} alt="builder right" />
            </div>
            <div className="col-12 col-md-8">
              <div className="note body-2 mb-3 fw-bold">
                Purposeful Investments. Strategic Growth.{" "}
              </div>
              <div className="note body-2 mb-3">
                The Lucky Duck Group's investment approach is built on accuracy,
                profound market understanding, and nurturing potential
                opportunities.{" "}
              </div>
              <div className="investmentphildata note body-2">
                <ul>
                  <li>
                    <b className="investmentphildatapoint fw-bold">
                      MARKET FOCUS
                    </b>
                  </li>
                  <div className="investmentphildatadesc">
                    We focus on prime waterfront properties in Southwest
                    Florida, where demand is strong, and value appreciation is
                    consistent. Our properties cater to buyers looking for
                    luxury residences, vacation homes, and high-return
                    investment opportunities.
                  </div>
                  <li>
                    <b className="investmentphildatapoint fw-bold">
                      EQUITY FINANCING
                    </b>
                  </li>
                  <div className="investmentphildatadesc">
                    We build futures together by partnering with accredited
                    individual and entity investors through limited equity
                    investment offerings. Our model ensures shared growth and
                    success.
                  </div>
                  <li>
                    <b className="investmentphildatapoint fw-bold">
                      EXCLUSIVE LOCATIONS
                    </b>
                  </li>
                  <div className="investmentphildatadesc">
                    We choose only the most coveted waterfront locations—with
                    spectacular views, direct water access, and high
                    appreciation potential. Enjoy exclusivity, ensure demand.
                  </div>
                  <li>
                    <b className="investmentphildatapoint fw-bold">
                      RISK MITIGATION
                    </b>
                  </li>
                  <div className="investmentphildatadesc">
                    By diversifying across high-demand markets and maintaining a
                    balanced portfolio, we strategically reduce exposure to
                    market fluctuations. Your investments are positioned for
                    growth with calculated risk.
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* investment strategy end */}
      {/* dualbanner  */}
      <InvestmentOpportunities />
      {/* <section className="row dualbannerinvrow">
        <div className="col-12 col-md-6 dualbannerinvone">
          <h6 className="title text-white">CURRENT INVESTMENT OPPORTUNITIES</h6>
          <p className="body-2 text-white">
            For more information, please click on button below
          </p>
          <br></br>

          <button
            className="tf-btn btn-view primary hover-btn-view ml-5 dualbannerinvonebtn text-black"
            onClick={() => {
              const url = new URL("/contact-us", window.location.origin);

              window.location.href = url.toString();
            }}
          >
            Contact Us
            <span className="icon icon-arrow-right2 dualbannerinvonebtnicon"></span>
          </button>
        </div>
        <div className="col-12 col-md-6 dualbannerinvsecpic"></div>
      </section> */}
      {/* dualbanner end */}
      {/* closed to new investors */}
      <section className="flat-section pb-0">
        <div className="container py-5 bg-primary-new">
          <div className=" box-title wow fadeInUp closedInv">
            <h3 className="title text-uppercase">Open to new investors</h3>
          </div>
          <OpenToInvestors />
        </div>
      </section>
      {/* <CompletedProjects /> */}
      <section className="flat-section pb-0">
        <div className="container py-5 bg-primary-new">
          <div className=" box-title wow fadeInUp closedInv">
            <h3 className="title text-uppercase">Closed to New Investors</h3>
          </div>
          <ClosedToInvestors />
        </div>
      </section>

      {/* end of closed to new investors */}

      {/* LDG Quote banner  */}

      {/* <section className="flat-section">
        <div className="container">
          <div
            className="row align-items-center justify-content-center wow fadeInUp ldgquote"
            data-wow-delay=".2s"
          >
            <div className="col-12 text-center">
              <h3 className="title text-white">
                Let's Build Our Future Together
              </h3>
              <div className="note body-2 text-white pt-2">
                LDG Development maintains transparent and professional
                communication with investors, providing regular updates on
                project progress and financial performance. We are a click or
                call away whenever you want to connect with us.
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <QuoteSection />
      {/* LDG Quote banner */}
    </div>
  );
};

// const dummyProperties = {
//   status: "success",
//   data: [
//     {
//       id: 1,
//       propertyName: "4230 Estero Blvd., Fort Myers Beach Florida",
//       propertyDesc:
//         "This Fort Myers Beach Project will consist of a 3 bedroom 2 ½ bathroom single family home suitable for sale as an owner-occupied residence or a turnkey income-generating vacation rental property. This property has gulf views and is steps away from the sugar sand beaches of the Gulf of America.",
//       SqFtUnderAC: "2300",
//       SqFtExteriorLiving: "581",
//       SqFtGarage: "300",
//       TotalProperty: 3827,
//       Featureone: "Single Family Home or Vacation Rental",
//       Featuretwo: "3 Bedrooms and 2 ½ Bathrooms",
//       Featurethree: "Exterior Living Porches and Roof Deck",
//       Featurefour: "Elevator stops at all 4 levels",
//       image: clinv1,
//     },
//     {
//       id: 2,
//       propertyName: "61 Flamingo St., Fort Myers Beach Florida",
//       propertyDesc:
//         "This Fort Myers Beach Duplex Project will consist of two units each as a 4 bedroom 4 ½ bathroom home suitable for sale as an owner-occupied residence or a turnkey income-generating vacation rental property. This property has partial gulf views and is steps away from the sugar sand beaches of the Gulf of America.",
//       SqFtUnderAC: "2946",
//       SqFtExteriorLiving: "581",
//       SqFtGarage: "300",
//       TotalProperty: 3827,
//       Featureone: "Duplex Vacation Rental (each unit)",
//       Featuretwo: "4 Bedrooms and 4 ½ Bathrooms",
//       Featurethree: "Exterior Living Porches and Roof Deck",
//       Featurefour: "Elevated Pool",
//       Featurefive: "Elevator stops at all 4 levels",
//       image: clinv2,
//     },
//   ],
// };

// const dummyProperties1 = {
//   status: "success",
//   data: [
//     {
//       id: 1,
//       propertyName: "4230 Estero Blvd., Fort Myers Beach Florida",
//       propertyDesc:
//         "This Fort Myers Beach Project will consist of a 3 bedroom 2 ½ bathroom single family home suitable for sale as an owner-occupied residence or a turnkey income-generating vacation rental property. This property has gulf views and is steps away from the sugar sand beaches of the Gulf of America.",
//       SqFtUnderAC: "2300",
//       SqFtExteriorLiving: "581",
//       SqFtGarage: "300",
//       TotalProperty: 3827,
//       Featureone: "Single Family Home or Vacation Rental",
//       Featuretwo: "3 Bedrooms and 2 ½ Bathrooms",
//       Featurethree: "Exterior Living Porches and Roof Deck",
//       Featurefour: "Elevator stops at all 4 levels",
//       image: opinv1, // Replace with actual image URL if available
//     },
//     {
//       id: 2,
//       propertyName: "61 Flamingo St., Fort Myers Beach Florida",
//       propertyDesc:
//         "This Fort Myers Beach Duplex Project will consist of two units each as a 4 bedroom 4 ½ bathroom home suitable for sale as an owner-occupied residence or a turnkey income-generating vacation rental property. This property has partial gulf views and is steps away from the sugar sand beaches of the Gulf of America.",
//       SqFtUnderAC: "2946",
//       SqFtExteriorLiving: "581",
//       SqFtGarage: "300",
//       TotalProperty: 3827,
//       Featureone: "Duplex Vacation Rental (each unit)",
//       Featuretwo: "4 Bedrooms and 4 ½ Bathrooms",
//       Featurethree: "Exterior Living Porches and Roof Deck",
//       Featurefour: "Elevated Pool",
//       Featurefive: "Elevator stops at all 4 levels",
//       image: opinv2, // Replace with actual image URL if available
//     },
//   ],
// };

const ClosedToInvestors = () => {
  const [properties, setProperties] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null);
  const itemsPerPage = 4;

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/propertiescloseInv`);
        if (response.data.status === "success") {
          setProperties(response.data.data);
        } else {
          setError("Failed to fetch properties.");
        }
      } catch (err) {
        setError("An error occurred while fetching data.");
      }
    };

    fetchProperties();
  }, []);

  const totalPages = Math.ceil(properties.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentProperties = properties.slice(indexOfFirstItem, indexOfLastItem);

  const nextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  return (
    <section className="completed-projects">
      <div>
        {error ? (
          <div className="text-center coming-soon note body-2 row px-md-4">
            <div className="col">
              <img
                src={ComingSoon}
                alt="Coming Soon"
                className="w-75 float-md-start"
              />
            </div>
            <div className="col align-content-around">
              <h3>Coming Soon</h3>
            </div>
          </div>
        ) : currentProperties.length > 0 ? (
          currentProperties.map((property) => (
            <div key={property.id} className="row mb-5 closedprop bg-white">
              <div className="buildercompletedprojectshero col-12 col-md-4 col-xl-2 text-center">
                <img
                  src={Image_Base_URL + property.image}
                  alt={property.title || "Property Image"}
                  className="project-image"
                />
              </div>

              <div className=" col-12 col-md-8 col-xl-10 p-md-3 pl-lg-4 pb-2">
                <div className="row  body-2 fw-bold pb-2 pt-1">
                  <div className="col">{property.title}</div>
                </div>
                <div className="row mb-2">
                  <div className="col-12 col-sm-6 col-xl-3 pb-md-2 pb-1">
                    Sq Ft Under AC:{" "}
                    <span className="fw-bold">
                      {property.sq_ft_under_ac || "null"}
                    </span>
                  </div>
                  <div className="col-12 col-sm-6 col-xl-3 pb-md-2 pb-1">
                    Sq Ft Exterior Living:{" "}
                    <span className="fw-bold">
                      {property.sq_ft_exterior_living || "null"}
                    </span>
                  </div>
                  <div className="col-12 col-sm-6 col-xl-3 pb-md-2 pb-1">
                    SqFt Garage:{" "}
                    <span className="fw-bold">
                      {property.sq_ft_garage || "null"}
                    </span>
                  </div>
                  <div className="col-12 col-sm-6 col-xl-3 pb-md-2 pb-1">
                    Each Unit SqFt:{" "}
                    <span className="fw-bold">
                      {property.each_unit_sqft || "null"}
                    </span>
                  </div>
                  <div className="col-12 col-sm-6 col-xl-3 pb-md-2 pb-1">
                    Total Property:{" "}
                    <span className="fw-bold">
                      {property.total_property || "null"}
                    </span>
                  </div>
                </div>
                <div className="row mb-2">
                  <div
                    dangerouslySetInnerHTML={{ __html: property.description }}
                  />
                </div>
                <div className="row pt-1 fw-bold">
                  {property.amenities.map((amenity, index) => (
                    <div
                      key={index}
                      className=" col-12 col-lg-6 col-xl-4 pb-md-2 pb-1"
                    >
                      {amenity}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center coming-soon note body-2 row px-md-4">
            <div className="col">
              <img
                src={ComingSoon}
                alt="Coming Soon"
                className="w-75 float-md-start"
              />
            </div>
            <div className="col align-content-around">
              <h3>Coming Soon</h3>
            </div>
          </div>
        )}
      </div>
      {currentProperties.length > 0 && !error && totalPages > 1 && (
        <div className="pagination-controls text-center">
          <button
            className="btn"
            onClick={prevPage}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <button
            className="btn"
            onClick={nextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      )}

      {/* {totalPages > 1 && (
        <div className="viewall">
          <a
            className="viewalllink body-2 viewalllink"
            href="/services/development/closed-investors"
          >
            View All Closed Projects
            <i className="fa-solid fa-chevron-right" aria-hidden="true"></i>
          </a>
        </div>
      )} */}
    </section>
  );
};

const OpenToInvestors = () => {
  const [properties, setProperties] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null);
  const itemsPerPage = 4;

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/propertiesopenInv`);
        if (response.data.status === "success") {
          setProperties(response.data.data);
        } else {
          setError("Failed to fetch properties. Please try again later.");
        }
      } catch (err) {
        setError("An error occurred while fetching the properties.");
      }
    };

    fetchProperties();
  }, []);

  const totalPages = Math.ceil(properties.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentProperties = properties.slice(indexOfFirstItem, indexOfLastItem);

  const nextPage = () =>
    currentPage < totalPages && setCurrentPage(currentPage + 1);
  const prevPage = () => currentPage > 1 && setCurrentPage(currentPage - 1);

  return (
    <section className="completed-projects">
      <div>
        {error ? (
          <div className="text-center coming-soon note body-2 row px-md-4">
            <div className="col">
              <img
                src={ComingSoon}
                alt="Coming Soon"
                className="w-75 float-md-start"
              />
            </div>
            <div className="col align-content-around">
              <h3>Coming Soon</h3>
            </div>
          </div>
        ) : currentProperties.length > 0 ? (
          currentProperties.map((property) => (
            <div key={property.id} className="row mb-5 closedprop bg-white">
              <div className="buildercompletedprojectshero col-12 col-md-4 col-xl-2 text-center">
                <img
                  src={Image_Base_URL + property.image}
                  alt={property.title || "Property Image"}
                  className="project-image"
                />
              </div>

              <div className=" col-12 col-md-8 col-xl-10 p-md-3 pl-lg-4 pb-2">
                <div className="row  body-2 fw-bold pb-2 pt-1">
                  <div className="col-12 col-sm-4  col-xl-2">
                    <span className="badge bg-success rounded-pill me-2 mb-1 px-2 py-1">
                      {property.comingSoon}
                    </span>
                  </div>
                  <div className="col">{property.title}</div>
                </div>
                <div className="row mb-2">
                  <div className="col-12 col-sm-6 col-xl-3 pb-md-2 pb-1">
                    Sq Ft Under AC:{" "}
                    <span className="fw-bold">
                      {property.sq_ft_under_ac || "null"}
                    </span>
                  </div>
                  <div className="col-12 col-sm-6 col-xl-3 pb-md-2 pb-1">
                    Sq Ft Exterior Living:{" "}
                    <span className="fw-bold">
                      {property.sq_ft_exterior_living || "null"}
                    </span>
                  </div>
                  <div className="col-12 col-sm-6 col-xl-3 pb-md-2 pb-1">
                    SqFt Garage:{" "}
                    <span className="fw-bold">
                      {property.sq_ft_garage || "null"}
                    </span>
                  </div>
                  <div className="col-12 col-sm-6 col-xl-3 pb-md-2 pb-1">
                    Each Unit SqFt:{" "}
                    <span className="fw-bold">
                      {property.each_unit_sqft || "null"}
                    </span>
                  </div>
                  <div className="col-12 col-sm-6 col-xl-3 pb-md-2 pb-1">
                    Total Property:{" "}
                    <span className="fw-bold">
                      {property.total_property || "null"}
                    </span>
                  </div>
                </div>
                <div className="row mb-2">
                  <div
                    dangerouslySetInnerHTML={{ __html: property.description }}
                  />
                </div>
                <div className="row pt-1 fw-bold">
                  {property.amenities.map((amenity, index) => (
                    <div
                      key={index}
                      className=" col-12 col-lg-6 col-xl-4 pb-md-2 pb-1"
                    >
                      {amenity}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center coming-soon note body-2 row px-md-4">
            <div className="col">
              <img
                src={ComingSoon}
                alt="Coming Soon"
                className="w-75 float-md-start"
              />
            </div>
            <div className="col align-content-around">
              <h3>Coming Soon</h3>
            </div>
          </div>
        )}
      </div>

      {totalPages > 1 && (
        <div className="pagination-controls text-center">
          <button
            className="btn"
            onClick={prevPage}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <button
            className="btn"
            onClick={nextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      )}
      {/* {totalPages > 1 && (
        <div className="viewall">
          <a
            className="viewalllink body-2 viewalllink"
            href="/services/development/open-investors"
          >
            View All Open Projects
            <i className="fa-solid fa-chevron-right" aria-hidden="true"></i>
          </a>
        </div>
      )} */}
    </section>
  );
};
const DevelopmentBanner = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const Preload = () => {
    return (
      <div className="preload preload-container">
        <div className="preload-logo">
          <div className="spinner" />
          <span className="icon icon-villa-fill" />
        </div>
      </div>
    );
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/development-banner`);
        if (
          response.data?.status === "success" &&
          response.data?.data?.length
        ) {
          setData(response.data.data[0]);
        } else {
          setError("No data available.");
        }
      } catch (err) {
        setError("Failed to load data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div>
        <Preload />
      </div>
    );
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <section className="flat-section">
      <div className="container">
        <div
          className="row align-items-center justify-content-center wow fadeInUp developmentbanner mx-md-5 px-1 px-md-5"
          style={{
            backgroundImage: `url(${Image_Base_URL + data.image})`,
          }}
          data-wow-delay=".2s"
        >
          <div className="col-12 text-center">
            <p className="subtitle text-white body-2 wow fadeInUp">
              {data?.title1 || "Welcome to"}{" "}
            </p>
            <h3 className="title text-white">
              {data?.title2 || "LUCKY DUCK DEVELOPMENT"}
            </h3>
          </div>
          <div className="col-12 align-items-left">
            <p
              className="subtitle text-white body-2 wow fadeInUp px-5"
              dangerouslySetInnerHTML={{
                __html: data?.description || "No Description",
              }}
            ></p>

            <button
              className="tf-btn btn-view primary hover-btn-view ml-5 mt-3"
              onClick={() => {
                const url = new URL("/contact-us", window.location.origin);

                window.location.href = url.toString();
              }}
            >
              {data.button_text || "Contact Us"}{" "}
              <span className="icon icon-arrow-right2"></span>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
const InvestmentStrategy = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/investment-strategy`);
        if (
          response.data.status === "success" &&
          response.data.data.length > 0
        ) {
          setData(response.data.data[0]); // Get the first object from the data array
        } else {
          setError("No data available.");
        }
      } catch (err) {
        setError("Failed to fetch data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-danger">{error}</div>;
  }

  return (
    <section className="flat-section bg-primary-new">
      <div className="container">
        <div className="box-title text-center wow fadeInUp">
          <h3 className="mt-4 title">{data.title}</h3>
        </div>
        <div
          className="row align-items-center justify-content-center wow fadeInUp"
          data-wow-delay=".2s"
        >
          <div className="col-12 col-md-4 text-center mb-2 dev-invest">
            <img
              src={`${Image_Base_URL}${data.image}`}
              alt="Investment strategy illustration"
            />
          </div>
          <div className="col-12 col-md-8">
            <div
              className="investmentphildata note body-2"
              dangerouslySetInnerHTML={{ __html: data.description }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

const InvestmentOpportunities = () => {
  const [investmentData, setInvestmentData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch data from API
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/call-to-action`);
        setInvestmentData(response.data.data); // Access the "data" array from the response
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch investment opportunities.");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <section className="row dualbannerinvrow">
      {investmentData.map((item) => (
        <>
          <div key={item.id} className="col-12 col-md-6 dualbannerinvone">
            <h6 className="title text-white">{item.title}</h6>
            <p className="body-2 text-white">{item.sub_title}</p>
            <br />
            <button className="tf-btn btn-view primary hover-btn-view ml-5 dualbannerinvonebtn">
              <Link
                to={item.button_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.button_text}{" "}
                <span className="icon icon-arrow-right2 dualbannerinvonebtnicon"></span>
              </Link>
            </button>
          </div>

          <div
            className="col-12 col-md-6 dualbannerinvsecpic"
            style={{
              backgroundImage: `url(${Image_Base_URL}${item.image})`, // Dynamically set background image
            }}
          ></div>
        </>
      ))}
    </section>
  );
};

const QuoteSection = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/bottom-banner`);
        if (
          response.data.status === "success" &&
          response.data.data.length > 0
        ) {
          setData(response.data.data[0]);
        } else {
          throw new Error(
            "Invalid API response structure or no data available."
          );
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="text-center">
        <PreLoad />
      </div>
    );
  }

  if (error) {
    return <div className="text-center text-danger">Error: {error}</div>;
  }

  return (
    <section className="flat-section">
      <div className="container">
        <div
          className="row align-items-center justify-content-center wow fadeInUp ldgquote mx-md-5"
          data-wow-delay=".2s"
          style={{ backgroundImage: `url(${Image_Base_URL}${data.bg_image})` }}
        >
          <div className="col-12 text-center">
            <h3 className="title text-white">
              {data?.title || "Title not available"}
            </h3>
            <div className="note body-2 text-white pt-2">
              {data?.footer_text || "Description not available"}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Development;
