import React, { useState, useEffect } from "react";
import { BASE_URL } from "new_components/BaseApi/BaseApi.js";
import { Image_Base_URL } from "new_components/BaseApi/BaseApi.js";
const FlatSlider = () => {
  const [sliderData, setSliderData] = useState(null);
  const [error, setError] = useState(null);
  const Preload = () => {
    return (
      <div className="preload preload-container">
        <div className="preload-logo">
          <div className="spinner" />
          <span className="icon icon-villa-fill" />
        </div>
      </div>
    );
  };
  useEffect(() => {
    const fetchSliderData = async () => {
      try {
        const response = await fetch(`${BASE_URL}/landing-banners`); // Replace with your API URL
        if (!response.ok) {
          throw new Error("Failed to fetch slider data");
        }
        const data = await response.json();
        setSliderData(data.data[0]);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchSliderData();
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!sliderData) {
    return (
      <div>
        <Preload />
      </div>
    );
  }

  return (
    <section className="flat-slider home-3">
      <div className="container position-relative z-1">
        <div className="row position-relative">
          <div className="col-xl-8 col-lg-7">
            <div className="slider-content">
              <div className="heading">
                <h5 className="title text-white">{sliderData.title}</h5>
                <h1 className="title-medium title text-white animationtext clip">
                  {sliderData.subtitle}
                </h1>
                <div
                  className="subtitle text-white body-2 wow fadeInUp"
                  data-wow-delay=".2s"
                  dangerouslySetInnerHTML={{ __html: sliderData.content }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="overlay"
        style={{
          backgroundImage: `url(${Image_Base_URL}${sliderData.background_image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
    </section>
  );
};

export default FlatSlider;
