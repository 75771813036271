//This component will check the stored token and dispatch the action to restore the user session.

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loginUser } from "store/authSlice";

const AuthLoader = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const token =
      localStorage.getItem("token") || sessionStorage.getItem("token");
    if (token) {
      dispatch(loginUser({ token })); // Restore session
    }
  }, [dispatch]);

  return null; // No UI needed
};

export default AuthLoader;
