import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "new_components/Header/Header";
import Footer from "new_components/Footer/Footer";
import { BASE_URL } from "new_components/BaseApi/BaseApi.js";
import { Image_Base_URL } from "new_components/BaseApi/BaseApi.js";
import ComingSoon from "new_assets/new_images/Builders/coming_soon.png";
const OpenInvestors = () => {
  return (
    <>
      <Header />
      <Body />
      <Footer />
    </>
  );
};

const Body = () => {
  const [properties, setProperties] = useState([]);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/propertiescloseInvs`);
        if (response.data.status === "success") {
          setProperties(response.data.data);
        } else {
          setError("Failed to fetch properties. Please try again later.");
        }
      } catch (err) {
        setError("An error occurred while fetching the properties.");
      }
    };

    fetchProperties();
  }, []);

  // Pagination logic
  const totalPages = Math.ceil(properties.length / itemsPerPage);
  const currentProperties = properties.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div id="wrapper">
      <div id="pagee" className="clearfix">
        <section className="flat-section flat-recommended pt-5">
          <div className="container">
            <div className="box-title-listing">
              <div className="box-left">
                <h3 className="fw-8">Open to New Investors</h3>
                <p className="text d-none">
                  There are currently 164,814 properties.
                </p>
              </div>
            </div>

            <section className="completed-projects">
              <div>
                {error ? (
                  <div className="text-center coming-soon note body-2 row">
                    <div className="col">
                      <img
                        src={ComingSoon}
                        alt="Coming Soon"
                        className="w-50"
                      />
                    </div>
                    <div className="col align-content-around">
                      <h3>Coming Soon</h3>
                    </div>
                  </div>
                ) : currentProperties.length > 0 ? (
                  currentProperties.map((property) => (
                    <div
                      key={property.id}
                      className="row mb-5 closedprop bg-white"
                    >
                      <div className="buildercompletedprojectshero col-12 col-md-4 col-xl-2 text-center">
                        <img
                          src={Image_Base_URL + property.image}
                          alt={property.title || "Property Image"}
                          className="project-image"
                        />
                      </div>

                      <div className=" col-12 col-md-8 col-xl-10 p-md-3 pl-lg-4 pb-2">
                        <div className="row  body-2 fw-bold pb-2 pt-1">
                          <div className="col-12 col-sm-4  col-xl-2">
                            <span className="badge bg-success rounded-pill me-2 mb-1 px-2 py-1">
                              {property.comingSoon}
                            </span>
                          </div>
                          <div className="col">{property.title}</div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-12 col-sm-6 col-xl-3 pb-md-2 pb-1">
                            Sq Ft Under AC:{" "}
                            <span className="fw-bold">
                              {property.sq_ft_under_ac}
                            </span>
                          </div>
                          <div className="col-12 col-sm-6 col-xl-3 pb-md-2 pb-1">
                            Sq Ft Exterior Living:{" "}
                            <span className="fw-bold">
                              {property.sq_ft_exterior_living}
                            </span>
                          </div>
                          <div className="col-12 col-sm-6 col-xl-3 pb-md-2 pb-1">
                            SqFt Garage:{" "}
                            <span className="fw-bold">
                              {property.sq_ft_garage}
                            </span>
                          </div>
                          <div className="col-12 col-sm-6 col-xl-3 pb-md-2 pb-1">
                            Total Property:{" "}
                            <span className="fw-bold">
                              {property.total_property}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: property.description,
                            }}
                          />
                        </div>
                        <div className="row pt-1 fw-bold">
                          {property.amenities.map((amenity, index) => (
                            <div
                              key={index}
                              className=" col-12 col-lg-6 col-xl-4 pb-md-2 pb-1"
                            >
                              {amenity}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-center coming-soon note body-2 ">
                    <h3>Coming Soon</h3>
                  </div>
                )}
              </div>

              {totalPages > 1 && (
                <div className="pagination-controls text-center">
                  <button
                    className="btn"
                    onClick={prevPage}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                  <button
                    className="btn"
                    onClick={nextPage}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </div>
              )}
              {/* {totalPages > 1 && (
        <div className="viewall">
          <a
            className="viewalllink body-2 viewalllink"
            href="/services/development/closed-investors"
          >
            View All Closed Projects
            <i className="fa-solid fa-chevron-right" aria-hidden="true"></i>
          </a>
        </div>
      )} */}
            </section>
          </div>
        </section>
      </div>
    </div>
  );
};

export default OpenInvestors;
