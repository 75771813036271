import React, { useState, useEffect } from "react";
import "new_components/AboutUs/AboutUs.scss";
import Header from "new_components/Header/Header";
import Footer from "new_components/Footer/Footer";
import aboutusgrowth from "new_assets/new_images/AboutUs/aboutusgrowth.png";
import aboutvalues from "new_assets/new_images/AboutUs/aboutvalues.png";
// import BottomBanner from "new_assets/new_images/AboutUs/banner.png";
import ProgressCircle from "new_components/ScrollProgress/ScrollProgress";
// import emily from "new_assets/new_images/AboutUs/emily.png";
// import david from "new_assets/new_images/AboutUs/david.png";
// import parker from "new_assets/new_images/AboutUs/parker.png";
// import daniel from "new_assets/new_images/AboutUs/daniel.png";
import axios from "axios";
import { BASE_URL } from "new_components/BaseApi/BaseApi.js";
import ScrollToTopDefault from "components/Layouts/LayoutPrimary/ScrollToTopDefault";
import { Image_Base_URL } from "new_components/BaseApi/BaseApi.js";
import PreLoad from "new_components/PreLoad/PreLoad";
const AboutUs = () => {
  return (
    <>
      <ScrollToTopDefault />
      <Header />
      <ProgressCircle />
      <Body />
      <Footer />
    </>
  );
};

const Body = () => {
  // const teamData = [
  //   {
  //     id: "teamModal1",
  //     name: "David A. Naccarato",
  //     imageSrc: david,
  //     description:
  //       "David serves as President of Lucky Duck Builders and a founding partner of the LDG Group. David has 20+ years as a finance and accounting professional leading large teams and vendors in system implementations across multiple highly regulated industries. He has completed several extensive renovations for single family homes and rental properties in California, Illinois and Florida. David holds a BS in Economics from UC Santa Barbara and an MBA from USC Marshall School of Business.",
  //   },
  //   {
  //     id: "teamModal2",
  //     name: "Daniel",
  //     imageSrc: daniel,
  //     description:
  //       "Daniel has over 30 years of construction management experience. His responsibilities and experience are in project management, overall supervision, contract management, sub-contractor management, and overall project execution from design development and owner review, through implementation, execution, and turnover of completed project. Daniel is the principal in charge of present construction management projects underway around the country for SDP Inc. and all its subsidiaries.  He has an educational focus in Civil Engineering, Transportation Systems and Construction Management through Vanderbilt University and Waubonsee Community College.  Registered Agent General Contracting: Tennesses #00066725; Florida #CGC1531243 ",
  //   },
  //   {
  //     id: "teamModal3",
  //     name: "Emily Gros Naccarato",
  //     imageSrc: emily,
  //     description:
  //       "Emily serves as President of LDG Development and a founding partner of the LDG Group. Emily has 20+ years of experience in the pharmaceutical industry.  She was an executive leader managing multi-million-dollar projects, large scale global teams of 100+ people, vendors and multi-year and phased complex timelines; including all aspects of resource, supply, cost and demand management. Additionally, she led the teams that performed several build out/construction projects in multiple states – commercial and residential.  Emily holds a BS in Industrial Engineering from Syracuse University and an MBA from the University of New Hampshire.  Additionally, she holds Executive Certification from both UPenn the Wharton School in Organizational Design and Change Management and Northwestern Kellogg School of Management in Pipeline Development.",
  //   },
  //   {
  //     id: "teamModal4",
  //     name: "Parker Marcer",
  //     imageSrc: parker,
  //     description:
  //       "Parker Mercer serves as an Associate in the LDG Development division. Parker is a proud graduate of James Madison University (Go Dukes!) where he received a B.S. in Psychology and William & Mary's School of Education where he earned a Master of Education in Policy, Planning & Leadership in Higher Education Administration.  In his free time, he enjoys traveling and spending time with my niece, nephews, and cousins. ",
  //   },
  // ];
  // const openModal = (id) => {
  //   const modal = new window.bootstrap.Modal(document.getElementById(id));
  //   modal.show(); // Open the modal with the provided id
  // };
  return (
    <>
      {/* {teamData.map((member) => (
        <TeamModal
          key={member.id}
          id={member.id}
          name={member.name}
          imageSrc={member.imageSrc}
          description={member.description}
        />
      ))} */}

      <div>
        {/* About Us Banner */}
        <AboutUsBanner />
        {/* <section className="flat-section">
          <div className="container">
            <div
              className="row align-items-center justify-content-center wow fadeInUp aboutusbanner mx-md-5"
              data-wow-delay=".2s"
            >
              <div className="col-12 text-center">
                <h3 className="title text-white text-uppercase">About Us</h3>
              </div>
            </div>
          </div>
        </section> */}
        {/* About Us Banner */}
        <GrowthSection />
        {/* <section className="flat-section pt-0">
          <div className="container">
            <div className="box-title text-center wow fadeInUp">
              <h3 className="mt-4 title">BUILT FOR GROWTH. DRIVEN BY VISION</h3>
            </div>
            <div
              className="row align-items-center justify-content-center wow fadeInUp"
              data-wow-delay=".2s"
            >
              <div className="col-12 col-md-8 mb-2">
                <div className="note body-2 mb-3">
                  Lucky Duck Group is a family-owned real estate investment and
                  development company that does things differently. We are a
                  team who call Southwest Florida our home and are excited by
                  the prospect of rebuilding it better than before. What started
                  as a passion for unique properties has grown into a wellspring
                  of real estate expertise, creating high-value developments
                  conceived to stand the test of time. Our goal is to evolve
                  beyond short term trends and create lasting legacies.{" "}
                </div>
                <div className="note body-2 mb-3">
                  We focus on high-growth markets, exclusive locations, and
                  sustainable developments to maximize returns for our
                  investors.
                </div>
              </div>

              <div className="col-12 col-md-4 text-center">
                <img src={aboutusgrowth} alt="builder right" />
              </div>
            </div>
          </div>
        </section> */}
        {/* About Us Banner */}
        <ValuesSection />
        {/* <section
          className="flat-section pt-0 flat-banner wow fadeInUpSmall"
          data-wow-delay=".2s"
          data-wow-duration="2000ms"
        >
          <div className="container">
            <div className="wrap-banner bg-primary-new">
              <div className="box-left">
                <div className="box-title">
                  <h3 className="mt-4 mb-3 fw-8">OUR VALUES</h3>
                  <div className="ourvaluesdata note body-2">
                    <ul>
                      <li>
                        <b className="ourvaluesdatapoint fw-bold">Integrity</b>
                      </li>
                      <div className="ourvaluesdatadesc">
                        Every investment is built on trust, transparency, and
                        long-term vision.
                      </div>
                      <li>
                        <b className="ourvaluesdatapoint fw-bold">Excellence</b>
                      </li>
                      <div className="ourvaluesdatadesc">
                        We set the standard in design, development, and
                        financial returns.
                      </div>
                      <li>
                        <b className="ourvaluesdatapoint fw-bold">Resilience</b>
                      </li>
                      <div className="ourvaluesdatadesc">
                        Our success is built on smart, future-proof investments.
                      </div>
                      <li>
                        <b className="ourvaluesdatapoint fw-bold">
                          Partnership
                        </b>
                      </li>
                      <div className="ourvaluesdatadesc">
                        We grow with our investors, ensuring success at every
                        step.
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="box-right">
                <img src={aboutvalues} alt="image" />
              </div>
            </div>
          </div>
        </section> */}
        <TeamSection />
        {/* <section className="flat-section flat-agents pt-0 pb-0">
          <div className="container">
            <div className="box-title text-center wow fadeInUp mb-3">
              <div className="text-subtitle text-primary">
                Strategists. Builders. Investors.
              </div>
              <div className="text-subtitle text-primary">
                And most importantly, locals.
              </div>
              <h3 className="title mt-4">MEET THE TEAM</h3>
            </div>
            <div className="body-2 mb-5">
              Behind Lucky Duck Group is a team of industry experts with deep
              experience in real estate investment, development, and management.
              We are residents of Southwest Florida deeply committed to the
              growth of the region. Between us, we bring strong financial
              insight, market intelligence, and uncompromising focus to the
              redevelopment effort of the SW Florida region.
            </div>
            <div className="tf-grid-layout xl-col-4 sm-col-2">
              <div
                className="box-agent hover-img wow fadeIn"
                data-wow-delay=".21s"
              >
                <a href="#" className="box-img img-style">
                  <img
                    className="lazyload"
                    data-src="images/agents/agent-1.jpg"
                    src={david}
                    alt="image-agent"
                  />
                </a>
                <div className="content">
                  <div className="info">
                    <h5>
                      <a className="link" href="#">
                        David A. Naccarato
                      </a>
                    </h5>
                    <p className="text-variant-1">
                      President Lucky Duck Builders
                    </p>
                  </div>
                  <div className="box-icon">
                    <span
                      className="icon fa-solid fa-info"
                      onClick={() => openModal("teamModal1")}
                    ></span>
                    
                  </div>
                </div>
              </div>
              <div
                className="box-agent hover-img wow fadeIn"
                data-wow-delay=".2s"
              >
                <a href="#" className="box-img img-style">
                  <img
                    className="lazyload"
                    data-src="images/agents/agent-2.jpg"
                    src={daniel}
                    alt="image-agent"
                  />
                </a>
                <div className="content">
                  <div className="info">
                    <h5>
                      <a className="link" href="#">
                        Daniel Kral
                      </a>
                    </h5>
                    <p className="text-variant-1">
                      VP and CEO SDP Inc VP Lucky Duck Builders
                    </p>
                  </div>
                  <div className="box-icon">
                    <span
                      className="icon fa-solid fa-info"
                      onClick={() => openModal("teamModal2")}
                    ></span>
                  </div>
                </div>
              </div>
              <div
                className="box-agent hover-img wow fadeIn"
                data-wow-delay=".3s"
              >
                <a href="#" className="box-img img-style">
                  <img
                    className="lazyload"
                    data-src="images/agents/agent-3.jpg"
                    src={emily}
                    alt="image-agent"
                  />
                </a>
                <div className="content">
                  <div className="info">
                    <h5>
                      <a className="link" href="#">
                        Emily Gros Naccarato
                      </a>
                    </h5>
                    <p className="text-variant-1">
                      President, Lucky Duck Development
                    </p>
                  </div>
                  <div className="box-icon">
                    <span
                      className="icon fa-solid fa-info"
                      onClick={() => openModal("teamModal3")}
                    ></span>
                  </div>
                </div>
              </div>
              <div
                className="box-agent hover-img wow fadeIn"
                data-wow-delay=".4s"
              >
                <a href="#" className="box-img img-style">
                  <img
                    className="lazyload"
                    data-src="images/agents/agent-4.jpg"
                    src={parker}
                    alt="image-agent"
                  />
                </a>
                <div className="content">
                  <div className="info">
                    <h5>
                      <a className="link" href="#">
                        {" "}
                        Parker Mercer
                      </a>
                    </h5>
                    <p className="text-variant-1">Associate, LDG Development</p>
                  </div>
                  <div className="box-icon">
                    <span
                      className="icon fa-solid fa-info"
                      onClick={() => openModal("teamModal4")}
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <MissionSection />
        {/* <section className="flat-section">
          <div className="container">
            <div
              className="row align-items-center justify-content-center wow fadeInUp aboutusfooterbanner"
              data-wow-delay=".2s"
            >
              <div className="col-12 text-center">
                <h3 className="title text-white">Our Mission</h3>
                <div className="note body-2 text-white pt-2">
                  Build enduring properties that embody the spirit of the
                  locality, stand the test of time, and generate outsized value
                  for everyone involved.
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </div>
    </>
  );
};

const AboutUsBanner = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/about-banners`);
        const firstItem = response.data?.data?.[0];

        if (firstItem && firstItem.is_active) {
          setData({
            title: firstItem.title,
            image: firstItem.image,
            altText: firstItem.alt_text,
          });
        } else {
          setError("No active banner data available.");
        }
      } catch (err) {
        setError("Failed to fetch the About Us data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div>
        <PreLoad />
      </div>
    );
  }

  if (error) {
    return <div className="error-message text-center text-danger">{error}</div>;
  }

  return (
    <section className="flat-section">
      <div className="container">
        <div
          className="row align-items-center justify-content-center wow fadeInUp mx-md-5 aboutusbanner"
          data-wow-delay=".2s"
          style={{
            backgroundImage: `url(${Image_Base_URL}${data.image})`, // Dynamically set background image
          }}
        >
          <div className="col-12 text-center">
            <h3 className="title text-white ">{data.title || "About Us"}</h3>
          </div>
        </div>
      </div>
    </section>
  );
};

const GrowthSection = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/about-content/growth`);
        setData(response.data.data);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch growth data. Please try again later.");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="text-center">
        <PreLoad />
      </div>
    );
  }

  if (error) {
    return <div className="text-center text-danger">{error}</div>;
  }

  return (
    <section className="flat-section pt-0">
      <div className="container">
        <div className="box-title text-center wow fadeInUp">
          <h3 className="mt-4 title">{data.title}</h3>
        </div>
        <div
          className="row align-items-center justify-content-center wow fadeInUp"
          data-wow-delay=".2s"
        >
          <div className="col-12 col-md-8 mb-2">
            <p className="body-2 mb-3">{data.content}</p>
          </div>
          <div className="col-12 col-md-4 text-center">
            <img
              src={Image_Base_URL + data.image}
              alt={data.alt_text}
              className="img-fluid"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
const ValuesSection = () => {
  const [valuesData, setValuesData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/about-content/values`);
        setValuesData(response.data.data);
      } catch (err) {
        setError("Failed to fetch values data. Please try again later.");
      }
    };

    fetchData();
  }, []);

  return (
    <section
      className="flat-section pt-0 flat-banner wow fadeInUpSmall"
      data-wow-delay=".2s"
      data-wow-duration="2000ms"
    >
      {error ? (
        <div className="error-message text-danger">{error}</div>
      ) : valuesData ? (
        <div className="container">
          <div className="wrap-banner bg-primary-new">
            <div className="box-left" key={valuesData.id}>
              <div className="box-title">
                <h3 className="mt-4 mb-3 fw-8">{valuesData.title}</h3>
                <div className="ourvaluesdata ourvaluesdatapoint ourvaluesdatadesc note body-2">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: valuesData.content || "Content not available.",
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="box-right">
              {valuesData.image ? (
                <img
                  src={Image_Base_URL + valuesData.image}
                  alt={valuesData.alt_text || "Image"}
                />
              ) : (
                <div className="placeholder">Loading image...</div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </section>
  );
};

const TeamSection = () => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/team`); // Replace with your API endpoint
        if (response.data?.status === "success") {
          setTeamMembers(response.data.data);
        } else {
          setError("Failed to fetch team members. Please try again later.");
        }
      } catch (err) {
        setError("Failed to fetch team members. Please try again later.");
      }
    };

    fetchTeamData();
  }, []);

  return (
    <section className="flat-section flat-agents pt-0">
      <div className="container">
        <div className="box-title text-center wow fadeInUp mb-3">
          <div className="text-subtitle text-primary">
            Strategists. Builders. Investors.
          </div>
          <div className="text-subtitle text-primary">
            And most importantly, locals.
          </div>
          <h3 className="title mt-4">MEET THE TEAM</h3>
        </div>
        <div className="body-2 mb-5">
          Behind Lucky Duck Group is a team of industry experts with deep
          experience in real estate investment, development, and management. We
          are residents of Southwest Florida deeply committed to the growth of
          the region. Between us, we bring strong financial insight, market
          intelligence, and uncompromising focus to the redevelopment effort of
          the SW Florida region.
        </div>
        {error ? (
          <div className="error-message text-center text-danger">{error}</div>
        ) : (
          <div className="tf-grid-layout xl-col-4 sm-col-2">
            {teamMembers.map((member, index) => (
              <div
                key={member.id}
                className="box-agent hover-img wow fadeIn"
                data-wow-delay={`${0.2 + index * 0.1}s`}
              >
                <div className="box-img img-style">
                  <img
                    className="lazyload"
                    data-src={
                      Image_Base_URL + member.image || "/placeholder-image.jpg"
                    } // Default image if none provided
                    src={
                      Image_Base_URL + member.image || "/placeholder-image.jpg"
                    }
                    alt={member.name}
                  />
                </div>
                <div className="content">
                  <div className="info">
                    <h5>{member.name}</h5>
                    <p className="text-variant-1">{member.designation}</p>
                  </div>
                  <div className="box-icon">
                    <span
                      className="icon fa-solid fa-info"
                      data-bs-toggle="modal"
                      data-bs-target={`#teamModal${member.id}`}
                    ></span>
                    {/* {member.email && (
                      <a
                        href={`mailto:${member.email}`}
                        className="icon icon-mail"
                      ></a>
                    )} */}
                    {/* {member.linkedin_url && (
                      <a
                        href={member.linkedin_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="icon icon-linkedin"
                      ></a>
                    )} */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Render modals for all team members */}
      {teamMembers.map((member) => (
        <TeamModal
          key={member.id}
          id={`teamModal${member.id}`}
          name={member.name}
          imageSrc={Image_Base_URL + member.image || "/placeholder-image.jpg"}
          description={member.bio || "No description available."}
        />
      ))}
    </section>
  );
};
const TeamModal = ({ id, name, imageSrc, description }) => {
  return (
    <div
      className="modal fade"
      id={id}
      tabIndex="-1"
      aria-labelledby={`${id}Label`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{name}</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="row align-items-center">
              <div className="col-4">
                <div className="aboutuspageteammember">
                  <img
                    className="aboutuspageteammember"
                    src={imageSrc}
                    alt={name}
                  />
                </div>
              </div>
              <div className="col-8">
                <div className="aboutuspageteammemberdesc">{description}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const MissionSection = () => {
  const [missionData, setMissionData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMissionData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/about-content/mission`); // Replace with your API endpoint
        setMissionData(response.data.data);
      } catch (err) {
        setError("Failed to fetch mission data. Please try again later.");
      }
    };

    fetchMissionData();
  }, []);

  if (error) {
    return (
      <section className="flat-section">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12 text-center">
              <p className="text-danger">{error}</p>
            </div>
          </div>
        </div>
      </section>
    );
  }

  if (!missionData) {
    return (
      <section className="flat-section">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12 text-center">
              <p className="text-white">Loading...</p>
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="flat-section">
      <div className="container">
        <div
          className="row align-items-center justify-content-center wow fadeInUp aboutusfooterbanner"
          style={{
            backgroundImage: `url(${Image_Base_URL}${missionData.image})`,
          }}
          data-wow-delay=".2s"
        >
          <div className="col-12 text-center">
            <h3 className="title text-white">{missionData.title}</h3>
            <div className="note body-2 text-white pt-2">
              {missionData.content}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
