const ENV = process.env.REACT_APP_ENV || "development";

// console.log(ENV);

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL ||
  "https://stage.luckyduckgroup.com/Admin";

//console.log(`Running in ${ENV} mode with API: ${API_BASE_URL}`);

const apiUrls = {
  loginApi: `${API_BASE_URL}/api/login`,
  devPropertiesList: `${API_BASE_URL}/api/devProperties`,
  devCompletedProperties: `${API_BASE_URL}/api/devCompProperties`,
  buildPropertiesList: `${API_BASE_URL}/api/buildProperties`,
  buyerPropertiesList: `${API_BASE_URL}/api/buyProperties`,
  detailedProperty: `${API_BASE_URL}/api/detailedProperty`,
  beforeAfterImages: `${API_BASE_URL}/api/beforeafter`,
  contactFormSubmit: `${API_BASE_URL}/api/register`,
  imagesURL: `${API_BASE_URL}/storage/app/public/`,
};

export default apiUrls;
