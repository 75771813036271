import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import ProgressCircle from "new_components/ScrollProgress/ScrollProgress"; // Import the ProgressCircle component
import "new_assets/fonts/fonts.css";
import "new_assets/fonts/font-icons.css";
import "new_assets/css/swiper-bundle.min.css";
import "new_assets/css/animate.css";
import "new_assets/css/styles.css";
import "new_assets/images/logo/favicon.png";
import "new_assets/css/jquery.fancybox.min.css";
import Header from "new_components/Header/Header";
import Footer from "new_components/Footer/Footer";
import "new_components/Careers/Careers.scss";
import banneritemone from "new_assets/new_images/Careers/item1.jpg";
import banneritemtwo from "new_assets/new_images/Careers/item2.jpg";
import { BASE_URL } from "new_components/BaseApi/BaseApi.js";
import { Image_Base_URL } from "new_components/BaseApi/BaseApi.js";
import PreLoad from "new_components/PreLoad/PreLoad";
import axios from "axios";
import ScrollToTopDefault from "components/Layouts/LayoutPrimary/ScrollToTopDefault";
function Careers() {
  // const [isModalOpen, SetIsModalOpen] = useState(false);
  // const toggleModal = () => {
  //   SetIsModalOpen(!isModalOpen);
  // };

  return (
    <>
      <ScrollToTopDefault />
      <Header />
      <ProgressCircle />
      <Body />
      <Footer />
      {/* {isModalOpen && <ContactModal onClose={toggleModal} />} */}
    </>
  );
}

function Body() {
  // const [jobListings, setJobListings] = useState([]);
  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);

  // useEffect(() => {
  //   const fetchJobListings = async () => {
  //     try {
  //       setLoading(true);
  //       const response = await fetch(`${BASE_URL}/jobs`);
  //       if (!response.ok) {
  //         throw new Error("");
  //       }
  //       const result = await response.json();

  //       setJobListings(Array.isArray(result.data) ? result.data : []);
  //     } catch (err) {
  //       setError(err.message);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchJobListings();
  // }, []);
  // function JobButton({ job }) {
  //   const navigate = useNavigate();

  //   const handleContactClick = () => {
  //     navigate(`/jobs/${job.id}`);
  //   };
  // }

  return (
    <div>
      {/* Discover opportunity Section */}
      <DiscoverOpportunitySection />
      {/* <section className="flat-section pb-0">
        <div className="container flat-header-wrapper-about w-75">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              <h1 className="title">DISCOVER THE OPPORTUNITY</h1>
              <p className="text-variant-1 desc mb-5">
                Lucky Duck Group is a family-owned real estate investment and
                development company. We are Southwest Florida locals with deep
                experience in finance and real estate and an undying love for
                the pristine beaches of the Sunshine State. Our mission is to
                contribute to the redevelopment of the region and its rapidly
                growing real estate investment market. We are a growing company
                with big dreams of building some of the finest beachfront
                properties in the state — and generating value for investors who
                put their trust in us.
              </p>

              <div className="box-img item1 ani5">
                <img src={banneritemone} alt="Banner Item One" />
              </div>
              <div className="box-img item2 ani4">
                <img src={banneritemtwo} alt="Banner Item Two" />
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* Discover opportunity Section End*/}
      <JobListingsSection />
      {/* <section className="flat-section bg-primary-new pt-5">
        <div className="container">
          <div
            className="align-items-center justify-content-center"
            data-wow-delay=".2s"
          >
            <div className="col-12 text-center">
              <h3 className="title text-black">
                Share our dream? Join the team.
              </h3>
              <div className="row talentrow">
                {loading && <p>Loading job listings...</p>}
                {error && <p style={{ color: "red" }}>{error}</p>}
                {(error || (!loading && jobListings.length === 0)) && (
                  <div>
                    <div className="note body-2 mb-3">
                      No positions available at this time, please check back
                      soon.
                    </div>
                    <button
                      onClick={onContactClick}
                      className="tf-btn btn-view primary hover-btn-view"
                    >
                      Contact us
                      <span className="icon icon-arrow-right2"></span>
                    </button>
                  </div>
                )}
                {!loading &&
                  !error &&
                  jobListings.map((job) => (
                    <div
                      className="col-12 col-md-4 pb-3"
                      key={job.id}
                      aria-labelledby={`job-title-${job.id}`}
                    >
                      <div className="careerpagecard text-start">
                        <div className="note body-2 mb-2 fw-bold">
                          {job.title}
                        </div>
                        <div className="note body-3 ">{job.location}</div>
                        <div className="note body-3 ">{job.experience}</div>

                        <button
                          type="button"
                          className="tf-btn btn-view primary hover-btn-view mt-3 applybtn"
                          aria-label={`Apply for ${job.title}`}
                          // onClick={handleContactClick}
                        >
                          View and Apply
                          <span className="icon icon-arrow-right2"></span>
                        </button>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
}
const DiscoverOpportunitySection = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/careerCms`);
        setData(response.data);
      } catch (err) {
        setError("Failed to fetch section data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div>
        <PreLoad />
      </div>
    );
  }

  if (error) {
    return <div className="text-center text-danger">{error}</div>;
  }

  return (
    <section className="flat-section pb-0">
      <div className="container flat-header-wrapper-about w-75">
        <div className="row justify-content-center">
          <div className="col-lg-8 text-center">
            <h1 className="title">{data.title}</h1>
            <p className="text-variant-1 desc mb-5">{data.content}</p>

            <div className="box-img item1 ani5">
              <img src={Image_Base_URL + data.image1} alt="Banner Item One" />
            </div>
            <div className="box-img item2 ani4">
              <img src={Image_Base_URL + data.image2} alt="Banner Item Two" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const JobListingsSection = () => {
  const [jobListings, setJobListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); // State for error handling
  const [selectedJob, setSelectedJob] = useState(null); // State for selected job details
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/jobs`);
        if (Array.isArray(response.data.data)) {
          setJobListings(response.data.data); // Access the "data" key
        } else {
          setJobListings([]); // Fallback to an empty array
        }
      } catch (err) {
        setError("Failed to fetch job listings. Please try again later.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchJobs();
  }, []);

  const openModal = (job) => {
    setSelectedJob(job);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedJob(null);
    setIsModalOpen(false);
  };

  return (
    <section className="flat-section bg-primary-new pt-5">
      <div className="container">
        <div
          className="align-items-center justify-content-center"
          data-wow-delay=".2s"
        >
          <div className="col-12 text-center">
            <h3 className="title text-black">
              Share our dream? Join the team.
            </h3>
            <div className="row talentrow">
              {loading && <p>Loading job listings...</p>}
              {error && <div className="text-danger">{error}</div>}
              {!loading && !error && jobListings.length === 0 && (
                <div>
                  <div className="note body-2 mb-3">
                    No positions available at this time, please check back soon.
                  </div>
                  <button
                    onClick={() => {
                      const url = new URL(
                        "/contact-us",
                        window.location.origin
                      );
                      url.searchParams.set("form", "form4");
                      window.location.href = url.toString();
                    }}
                    className="tf-btn btn-view primary hover-btn-view"
                  >
                    Contact us
                    <span className="icon icon-arrow-right2"></span>
                  </button>
                </div>
              )}
              {!loading &&
                !error &&
                Array.isArray(jobListings) &&
                jobListings.map((job) => (
                  <div className="col-12 col-md-4 pb-3" key={job.id}>
                    <div className="careerpagecard text-start">
                      <div className="note body-2 mb-2 fw-bold">
                        {job.title}
                      </div>
                      <div className="note body-3">{job.location}</div>
                      <div className="note body-3">{job.experience}</div>
                      <div className="row mt-3">
                        <div className="col">
                          <button
                            type="button"
                            className="tf-btn btn-view primary hover-btn-view applybtn"
                          >
                            Apply
                            <span className="icon icon-arrow-right2"></span>
                          </button>
                        </div>
                        <div
                          className="cover-icon"
                          onClick={() => openModal(job)}
                        >
                          <span className="icon fa-solid fa-info"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      {isModalOpen && <Modal job={selectedJob} onClose={closeModal} />}
    </section>
  );
};

const Modal = ({ job, onClose }) => {
  return (
    <div
      className="modal fade show"
      style={{
        display: "block",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 1050,
      }}
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog modal-lg p-3 p-md-5" role="document">
        <div className="modal-content">
          <div className="modal-header justify-content-between">
            <h5 className="modal-title fs-5">{job?.title || "Job Details"}</h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={onClose}
            ></button>
          </div>
          <hr className="m-0" />
          <div className="modal-body mb-2">
            <div className="row">
              <div className="col-12 col-lg-4">
                <strong>Work Location: </strong>
                <span className="fw-bold">{job?.location || "N/A"}</span>
              </div>
              <div className="col-12 col-lg-4">
                <strong>Role: </strong>
                <span className="fw-bold">{job?.title || "N/A"}</span>
              </div>
              <div className="col-12 col-lg-4">
                <strong>Experience: </strong>
                <span className="fw-bold">{job?.experience || "N/A"}</span>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col">
                <strong className="fw-bold">Job Description</strong>
                {job?.job_description ? (
                  <div
                    dangerouslySetInnerHTML={{ __html: job.job_description }}
                  ></div>
                ) : (
                  <p>Description not available.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Careers;
