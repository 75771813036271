import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "new_components/BaseApi/BaseApi.js";
import { Image_Base_URL } from "new_components/BaseApi/BaseApi.js";

const HomeBottomBanner = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/bottom-banner`);
        if (
          response.data.status === "success" &&
          response.data.data.length > 0
        ) {
          setData(response.data.data[0]);
        } else {
          throw new Error(
            "Invalid API response structure or no data available."
          );
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-danger">Error: {error}</div>;
  }

  return (
    <section className="flat-section">
      <div className="container">
        <div
          className="row align-items-center justify-content-center wow fadeInUp ldgquote mx-md-5"
          data-wow-delay=".2s"
          style={{ backgroundImage: `url(${Image_Base_URL}${data.bg_image})` }}
        >
          <div className="col-12 text-center">
            <h3 className="title text-white">{data.title}</h3>
          </div>
        </div>
      </div>
    </section>
  );
};
export default HomeBottomBanner;
