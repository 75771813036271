import React, { useState, useEffect } from "react";
import "new_components/ScrollProgress/ScrollProgress.scss";

function ProgressCircle({ scrollThreshold = 200, onClick }) {
  const [progress, setProgress] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  // Default scroll-to-top handler
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;

      const totalScrollableDistance = scrollHeight - clientHeight;
      const calculatedProgress = Math.min(
        (scrollTop / totalScrollableDistance) * 100,
        100
      );
      setProgress(calculatedProgress);

      if (scrollTop > scrollThreshold) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollThreshold]);

  const circleRadius = 49;
  const circumference = 2 * Math.PI * circleRadius;
  const offset = circumference - (progress / 100) * circumference;

  return (
    isVisible && (
      <div className="progress-wrap" onClick={onClick || handleScrollToTop}>
        <svg
          className="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path
            d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
            style={{
              strokeDasharray: `${circumference}, ${circumference}`,
              strokeDashoffset: offset,
            }}
          />
        </svg>
      </div>
    )
  );
}

export default ProgressCircle;
