// import React, { useState, useEffect } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import { Link } from "react-router-dom";
// import "new_components/ContactUs/ContactUs.scss";
// import "new_assets/fonts/fonts.css";
// import "new_assets/fonts/fonts.css";
// import "new_assets/fonts/font-icons.css";
// import "new_assets/css/swiper-bundle.min.css";
// import "new_assets/css/animate.css";
// import "new_assets/css/styles.css";
// import "new_assets/images/logo/favicon.png";
// import "new_assets/css/jquery.fancybox.min.css";
// import contactusbanner from "new_assets/new_images/ContactUs/contactusbanner.png";
// import Header from "new_components/Header/Header";
// import Footer from "new_components/Footer/Footer";
// import ProgressCircle from "new_components/ScrollProgress/ScrollProgress";
// import axios from "axios";
// import ScrollToTopDefault from "components/Layouts/LayoutPrimary/ScrollToTopDefault";
// function ContactUs() {
//   const [loading, setLoading] = useState(true);
//   useEffect(() => {
//     setTimeout(() => {
//       setLoading(false);
//     }, 300);
//   }, []);
//   return (
//     <>
//       {loading ? (
//         <Preload /> // Shows the preload screen while loading
//       ) : (
//         <>
//           <ScrollToTopDefault />
//           <Header />
//           <ProgressCircle />
//           <Body />
//           <Footer />
//         </>
//       )}
//     </>
//   );
// }
// const Preload = () => {
//   return (
//     <div className="preload preload-container">
//       <div className="preload-logo">
//         <div className="spinner" />
//         <span className="icon icon-villa-fill" />
//       </div>
//     </div>
//   );
// };
// function Body() {
//   const [selectedForm, setSelectedForm] = useState("form1");
//   const location = useLocation();
//   const navigate = useNavigate();

//   useEffect(() => {
//     const params = new URLSearchParams(location.search);
//     const formParam = params.get("form");
//     if (formParam && ["form1", "form2", "form3", "form4"].includes(formParam)) {
//       setSelectedForm(formParam);
//     } else {
//       setSelectedForm("form1"); // Fallback to form1 if no valid form parameter exists
//     }
//   }, [location.search]);

//   // Define the handleRadioChange function
//   const handleRadioChange = (value) => {
//     setSelectedForm(value);
//     navigate(`?form=${value}`);
//   };

//   return (
//     <div>
//       <section className="flat-section">
//         <div className="container">
//           <div
//             className="row align-items-center justify-content-center wow fadeInUp contactusbanner mx-md-5"
//             data-wow-delay=".2s"
//           >
//             <div className="col-12 text-center">
//               <h3 className="title text-white text-uppercase">Contact Us</h3>
//             </div>
//           </div>
//         </div>
//       </section>
//       <section className="flat-section flat-contact contactus-body pb-0">
//         <div className="container">
//           <div className="row">
//             <div className="col-lg-8 pb-2 pb-md-0">
//               <h4 className="mb-1 text-uppercase">Let's get in touch</h4>
//               <div className="body-2">
//                 How can we help? Let us know by filling in the form and we will
//                 reach out shortly.
//               </div>
//               <div className="container mt-3 contactform">
//                 <form className="d-flex justify-content-start row px-4">
//                   <div className="form-check col-6 col-md-3">
//                     <input
//                       className="form-check-input"
//                       type="radio"
//                       name="forms"
//                       id="form1"
//                       value="form1"
//                       checked={selectedForm === "form1"}
//                       onChange={() => handleRadioChange("form1")}
//                     />
//                     <label
//                       className="form-check-label note body-2"
//                       htmlFor="form1"
//                     >
//                       Investor
//                     </label>
//                   </div>
//                   <div className="form-check col-6 col-md-3">
//                     <input
//                       className="form-check-input"
//                       type="radio"
//                       name="forms"
//                       id="form2"
//                       value="form2"
//                       checked={selectedForm === "form2"}
//                       onChange={() => handleRadioChange("form2")}
//                     />
//                     <label
//                       className="form-check-label note body-2"
//                       htmlFor="form2"
//                     >
//                       Vendor
//                     </label>
//                   </div>
//                   <div className="form-check col-6 col-md-3">
//                     <input
//                       className="form-check-input"
//                       type="radio"
//                       name="forms"
//                       id="form3"
//                       value="form3"
//                       checked={selectedForm === "form3"}
//                       onChange={() => handleRadioChange("form3")}
//                     />
//                     <label
//                       className="form-check-label note body-2"
//                       htmlFor="form3"
//                     >
//                       General Inquiry
//                     </label>
//                   </div>
//                   <div className="form-check col-6 col-md-3">
//                     <input
//                       className="form-check-input"
//                       type="radio"
//                       name="forms"
//                       id="form4"
//                       value="form4"
//                       checked={selectedForm === "form4"}
//                       onChange={() => handleRadioChange("form4")}
//                     />
//                     <label
//                       className="form-check-label note body-2"
//                       htmlFor="form4"
//                     >
//                       Recruitment
//                     </label>
//                   </div>
//                 </form>
//                 <div
//                   className="mt-4"
//                   style={{
//                     position: "relative",
//                     width: "100%",
//                     height: "840px",
//                   }}
//                 >
//                   {selectedForm === "form1" && (
//                     <iframe
//                       src="https://luckyduckgroup.cashflowportal.com/leads/39bfd02451174a2e965cb1154ef43441"
//                       style={{
//                         position: "absolute",
//                         top: 0,
//                         left: 0,
//                         width: "100%",
//                         height: "100%",
//                       }}
//                     ></iframe>
//                   )}
//                   {selectedForm === "form2" && (
//                     <iframe
//                       src="https://luckyduckgroup.cashflowportal.com/leads/f90221c325e04dc99cc086115734c873"
//                       style={{
//                         position: "absolute",
//                         top: 0,
//                         left: 0,
//                         width: "100%",
//                         height: "100%",
//                       }}
//                     ></iframe>
//                   )}
//                   {selectedForm === "form3" && (
//                     <iframe
//                       src="https://luckyduckgroup.cashflowportal.com/leads/381620cf34b740929d10c1e715e37224"
//                       style={{
//                         position: "absolute",
//                         top: 0,
//                         left: 0,
//                         width: "100%",
//                         height: "100%",
//                       }}
//                     ></iframe>
//                   )}
//                   {selectedForm === "form4" && (
//                     <iframe
//                       src="https://luckyduckgroup.cashflowportal.com/leads/6ba8c8f60dd14b879b66a8cde60c9356"
//                       style={{
//                         position: "absolute",
//                         top: 0,
//                         left: 0,
//                         width: "100%",
//                         height: "100%",
//                       }}
//                     ></iframe>
//                   )}
//                 </div>
//               </div>
//             </div>
//             <div className="col-lg-4 pb-4">
//               <div className="contact-info">
//                 <h4>Contact Us</h4>
//                 <ul>
//                   {/* <li className="box">
//                     <h6 className="title">Address:</h6>
//                     <p className="text-variant-1">
//                       101 E 129th St, East Chicago, IN 46312
//                       <br /> United States
//                     </p>
//                   </li> */}
//                   <li className="box">
//                     <h6 className="title">Infomation:</h6>
//                     <p className="text-variant-1">
//                       239-387-2171 <br />{" "}
//                       <div>
//                         <a href="mailto:info@luckyduckgroup.com">
//                           info@luckyduckgroup.com
//                         </a>
//                       </div>
//                     </p>
//                   </li>
//                   <li className="box">
//                     <div className="title">Opentime: </div>
//                     <p className="text-variant-1">
//                       Monday - Friday 9am - 4pm EST
//                     </p>
//                     <p className="text-variant-1">by appointments</p>
//                   </li>
//                   <li className="box">
//                     <div className="title">Follow Us:</div>
//                     <ul className="box-social">
//                       {/* <li>
//                         <a href="#" className="item">
//                           <svg
//                             width="10"
//                             height="18"
//                             viewBox="0 0 10 18"
//                             fill="none"
//                             xmlns="http://www.w3.org/2000/svg"
//                           >
//                             <path
//                               d="M9.00879 10.125L9.50871 6.86742H6.38297V4.75348C6.38297 3.86227 6.81961 2.99355 8.21953 2.99355H9.64055V0.220078C9.64055 0.220078 8.35102 0 7.11809 0C4.54395 0 2.86137 1.56023 2.86137 4.38469V6.86742H0V10.125H2.86137V18H6.38297V10.125H9.00879Z"
//                               fill="#161E2D"
//                             />
//                           </svg>
//                         </a>
//                       </li> */}
//                       <li>
//                         <a
//                           href="https://www.linkedin.com/company/luckyduckgroup/"
//                           target="_blank"
//                           rel="noreferrer"
//                           className="item"
//                         >
//                           <svg
//                             width="16"
//                             height="16"
//                             viewBox="0 0 16 16"
//                             fill="none"
//                             xmlns="http://www.w3.org/2000/svg"
//                           >
//                             <path
//                               d="M3.58151 16H0.264292V5.31762H3.58151V16ZM1.92111 3.86044C0.860376 3.86044 0 2.98185 0 1.92111C7.59231e-09 1.4116 0.202403 0.92296 0.562681 0.562681C0.92296 0.202403 1.4116 0 1.92111 0C2.43063 0 2.91927 0.202403 3.27955 0.562681C3.63983 0.92296 3.84223 1.4116 3.84223 1.92111C3.84223 2.98185 2.98149 3.86044 1.92111 3.86044ZM15.9968 16H12.6867V10.7999C12.6867 9.56057 12.6617 7.97125 10.962 7.97125C9.23735 7.97125 8.97306 9.31771 8.97306 10.7106V16H5.65941V5.31762H8.84091V6.77479H8.88734C9.33021 5.93549 10.412 5.04976 12.026 5.04976C15.3832 5.04976 16.0004 7.26052 16.0004 10.132V16H15.9968Z"
//                               fill="black"
//                             ></path>
//                           </svg>
//                         </a>
//                       </li>
//                       {/* <li>
//                         <a href="#" className="item">
//                           <svg
//                             width="20"
//                             height="14"
//                             viewBox="0 0 20 14"
//                             fill="none"
//                             xmlns="http://www.w3.org/2000/svg"
//                           >
//                             <path
//                               d="M19.2775 2.16608C19.051 1.31346 18.3839 0.641967 17.5368 0.414086C16.0013 0 9.84445 0 9.84445 0C9.84445 0 3.68759 0 2.15212 0.414086C1.30502 0.642003 0.637857 1.31346 0.411419 2.16608C0 3.71149 0 6.93586 0 6.93586C0 6.93586 0 10.1602 0.411419 11.7056C0.637857 12.5583 1.30502 13.2018 2.15212 13.4296C3.68759 13.8437 9.84445 13.8437 9.84445 13.8437C9.84445 13.8437 16.0013 13.8437 17.5368 13.4296C18.3839 13.2018 19.051 12.5583 19.2775 11.7056C19.6889 10.1602 19.6889 6.93586 19.6889 6.93586C19.6889 6.93586 19.6889 3.71149 19.2775 2.16608ZM7.8308 9.86334V4.00837L12.9767 6.93593L7.8308 9.86334Z"
//                               fill="#161E2D"
//                             />
//                           </svg>
//                         </a>
//                       </li>
//                       <li>
//                         <a href="#" className="item">
//                           <svg
//                             width="19"
//                             height="16"
//                             viewBox="0 0 19 16"
//                             fill="none"
//                             xmlns="http://www.w3.org/2000/svg"
//                           >
//                             <path
//                               d="M16.9797 3.83062C16.9917 3.99873 16.9917 4.16688 16.9917 4.33498C16.9917 9.46249 13.089 15.3706 5.95611 15.3706C3.75858 15.3706 1.71719 14.7341 0 13.6294C0.312227 13.6654 0.612403 13.6774 0.936643 13.6774C2.74986 13.6774 4.41904 13.065 5.75196 12.0203C4.04678 11.9843 2.61779 10.8675 2.12545 9.33042C2.36563 9.36643 2.60578 9.39045 2.85798 9.39045C3.20621 9.39045 3.55447 9.3424 3.87868 9.25838C2.10146 8.8981 0.768498 7.33705 0.768498 5.45175V5.40373C1.28483 5.69193 1.8853 5.87205 2.52169 5.89604C1.47697 5.19955 0.792524 4.01075 0.792524 2.66581C0.792524 1.94533 0.984621 1.28487 1.32087 0.70847C3.2302 3.06209 6.10019 4.59912 9.31838 4.76727C9.25835 4.47907 9.22231 4.17889 9.22231 3.87868C9.22231 1.74118 10.9515 0 13.101 0C14.2177 0 15.2264 0.468321 15.9349 1.22484C16.8115 1.05674 17.6521 0.732496 18.3966 0.288201C18.1084 1.18884 17.496 1.94536 16.6915 2.42566C17.472 2.34164 18.2285 2.12545 18.925 1.82527C18.3967 2.59377 17.7362 3.27821 16.9797 3.83062Z"
//                               fill="#161E2D"
//                             />
//                           </svg>
//                         </a>
//                       </li>
//                       <li>
//                         <a href="#" className="item">
//                           <svg
//                             width="14"
//                             height="18"
//                             viewBox="0 0 14 18"
//                             fill="none"
//                             xmlns="http://www.w3.org/2000/svg"
//                           >
//                             <path
//                               d="M7.35713 0C3.65693 0 0 2.4668 0 6.45913C0 8.99806 1.42815 10.4406 2.29369 10.4406C2.65073 10.4406 2.8563 9.44526 2.8563 9.16395C2.8563 8.82856 2.00157 8.11448 2.00157 6.71879C2.00157 3.81922 4.20871 1.76355 7.06501 1.76355C9.52099 1.76355 11.3386 3.15924 11.3386 5.72341C11.3386 7.63843 10.5705 11.2304 8.08202 11.2304C7.18402 11.2304 6.41585 10.5813 6.41585 9.65082C6.41585 8.28759 7.36795 6.96763 7.36795 5.56112C7.36795 3.17366 3.9815 3.60644 3.9815 6.49158C3.9815 7.09747 4.05724 7.76826 4.32772 8.32005C3.83003 10.4623 2.81302 13.654 2.81302 15.8611C2.81302 16.5427 2.91039 17.2135 2.97531 17.8951C3.09793 18.0322 3.03662 18.0178 3.22415 17.9492C5.0418 15.4608 4.97688 14.9739 5.79915 11.7173C6.24274 12.5612 7.38959 13.0156 8.29841 13.0156C12.1284 13.0156 13.8487 9.28297 13.8487 5.91816C13.8487 2.33697 10.7544 0 7.35713 0Z"
//                               fill="#161E2D"
//                             />
//                           </svg>
//                         </a>
//                       </li> */}
//                     </ul>
//                   </li>
//                   <li className="box">
//                     {selectedForm !== "form2" &&
//                       selectedForm !== "form3" &&
//                       selectedForm !== "form4" && (
//                         <>
//                           <h6 className="title">
//                             Already have a CashFlow account?
//                           </h6>
//                           <button className="btn btn-primary">
//                             <Link
//                               to="https://luckyduckgroup.cashflowportal.com/accounts/login"
//                               className="text-white"
//                               target="_blank"
//                               rel="noopener noreferrer"
//                             >
//                               Login
//                             </Link>
//                           </button>
//                         </>
//                       )}
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// }

// export default ContactUs;

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import "new_components/ContactUs/ContactUs.scss";
import "new_assets/fonts/fonts.css";
import "new_assets/css/styles.css";
import Header from "new_components/Header/Header";
import Footer from "new_components/Footer/Footer";
import ProgressCircle from "new_components/ScrollProgress/ScrollProgress";
import ScrollToTopDefault from "components/Layouts/LayoutPrimary/ScrollToTopDefault";
import { BASE_URL } from "new_components/BaseApi/BaseApi.js";
import { Image_Base_URL } from "new_components/BaseApi/BaseApi.js";
import PreLoad from "new_components/PreLoad/PreLoad";

function ContactUs() {
  return (
    <>
      <ScrollToTopDefault />
      <Header />
      <ProgressCircle />
      <Body />
      <Footer />
    </>
  );
}

function Body() {
  const [selectedForm, setSelectedForm] = useState("form1");
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${BASE_URL}/contactCms`) // Replace with the actual API endpoint
      .then((response) => {
        setData(response.data);
      })
      .catch((err) => {
        setError("Failed to load contact information. Please try again later.");
      });
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const formParam = params.get("form");
    if (formParam && ["form1", "form2", "form3", "form4"].includes(formParam)) {
      setSelectedForm(formParam);
    } else {
      setSelectedForm("form1");
    }
  }, [location.search]);

  const handleRadioChange = (value) => {
    setSelectedForm(value);
    navigate(`?form=${value}`);
  };

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!data) {
    return (
      <div className="loading-message">
        <PreLoad />
      </div>
    );
  }

  return (
    <div>
      <section className="flat-section">
        <div className="container">
          <div
            className="row align-items-center justify-content-center wow fadeInUp contactusbanner mx-md-5"
            style={{
              backgroundImage: `url(${Image_Base_URL}${data.image1})`,
            }}
            data-wow-delay=".2s"
          >
            <div className="col-12 text-center">
              <h3 className="title text-white text-uppercase">{data.title}</h3>
            </div>
          </div>
        </div>
      </section>
      <section className="flat-section flat-contact contactus-body pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 pb-2 pb-md-0">
              <h4 className="mb-1 text-uppercase">{data.sub_title}</h4>
              <div className="body-2">{data.content}</div>
              <div className="container mt-3 contactform">
                <form className="d-flex justify-content-start row px-4">
                  {["form1", "form2", "form3", "form4"].map((form, index) => (
                    <div className="form-check col-6 col-md-3" key={form}>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="forms"
                        id={form}
                        value={form}
                        checked={selectedForm === form}
                        onChange={() => handleRadioChange(form)}
                      />
                      <label
                        className="form-check-label note body-2"
                        htmlFor={form}
                      >
                        {data[`rdobtn${index + 1}`]}
                      </label>
                    </div>
                  ))}
                </form>
                <div
                  className="mt-4"
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "840px",
                  }}
                >
                  <iframe
                    src={
                      selectedForm === "form1"
                        ? data.formUrlInv
                        : selectedForm === "form2"
                        ? data.formUrlVen
                        : selectedForm === "form3"
                        ? data.formUrlGenEnq
                        : data.formUrlRec
                    }
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  ></iframe>
                </div>
              </div>
            </div>

            <div className="col-lg-4 pb-4">
              <div className="contact-info">
                <div dangerouslySetInnerHTML={{ __html: data.contactInfo }} />
                <li class="box">
                  <ul class="box-social">
                    <li>
                      <a
                        href={data.socialMed1}
                        target="_blank"
                        rel="noreferrer"
                        class="item"
                      >
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.58151 16H0.264292V5.31762H3.58151V16ZM1.92111 3.86044C0.860376 3.86044 0 2.98185 0 1.92111C7.59231e-09 1.4116 0.202403 0.92296 0.562681 0.562681C0.92296 0.202403 1.4116 0 1.92111 0C2.43063 0 2.91927 0.202403 3.27955 0.562681C3.63983 0.92296 3.84223 1.4116 3.84223 1.92111C3.84223 2.98185 2.98149 3.86044 1.92111 3.86044ZM15.9968 16H12.6867V10.7999C12.6867 9.56057 12.6617 7.97125 10.962 7.97125C9.23735 7.97125 8.97306 9.31771 8.97306 10.7106V16H5.65941V5.31762H8.84091V6.77479H8.88734C9.33021 5.93549 10.412 5.04976 12.026 5.04976C15.3832 5.04976 16.0004 7.26052 16.0004 10.132V16H15.9968Z"
                            fill="black"
                          ></path>
                        </svg>
                      </a>
                    </li>
                  </ul>
                </li>

                <li className="box">
                  {selectedForm !== "form2" &&
                    selectedForm !== "form3" &&
                    selectedForm !== "form4" && (
                      <>
                        <h6 className="title">
                          Already have a CashFlow account?
                        </h6>
                        <button className="btn btn-primary">
                          <Link
                            to={data.cashflow_login_link}
                            className="text-white"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Login
                          </Link>
                        </button>
                      </>
                    )}
                </li>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ContactUs;
