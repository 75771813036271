import React from "react";
import { Link } from "react-router-dom";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error("ErrorBoundary caught an error", error, errorInfo);
  }

  render() {
    //If hasError is true, it renders a fallback UI with an error message and a link to the homepage.

    return (
      <div>
        <h2>Something went wrong</h2>
        <p>{this.state.error?.message || "An unexpected error occurred."}</p>
        <Link to="/" aria-label="Home">
          Go to Home
        </Link>
      </div>
    );
  }
}

export default ErrorBoundary;
