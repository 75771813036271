import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "new_components/BaseApi/BaseApi.js";
import { Image_Base_URL } from "new_components/BaseApi/BaseApi.js";
const WhatWeDo = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/landing-banners`);
        if (response.data.status === "success") {
          setData(response.data.data[0]);
        } else {
          throw new Error("Failed to fetch data");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <section className="flat-section">
      <div className="container">
        <div className="box-title text-center wow fadeInUp">
          <h3 className="mt-4 title text-uppercase">{data.section_heading}</h3>
        </div>
        <div
          className="tf-grid-layout md-col-2 wow fadeInUp"
          data-wow-delay=".2s"
        >
          {data.card_1_enabled && (
            <div className="box-service">
              <div className="image">
                <img
                  className="lazyload"
                  src={`${Image_Base_URL}${data.card_image_1}`}
                  alt="Explore Investments"
                />
              </div>
              <div className="content">
                <a href="/services/development" className="tf-btn btn-line">
                  {data.card_1_button_text}
                  <span className="icon icon-arrow-right2" />
                </a>
              </div>
            </div>
          )}

          {data.card_2_enabled && (
            <div className="box-service">
              <div className="image">
                <img
                  className="lazyload"
                  src={`${Image_Base_URL}${data.card_image_2}`}
                  alt="Explore Constructions"
                />
              </div>
              <div className="content">
                <a href="/services/builders" className="tf-btn btn-line">
                  {data.card_2_button_text}
                  <span className="icon icon-arrow-right2" />
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default WhatWeDo;
