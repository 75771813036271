import React, { useState, useEffect } from "react";
import ScrollToTopDefault from "components/Layouts/LayoutPrimary/ScrollToTopDefault";
import { Link } from "react-router-dom";
import Header from "new_components/Header/Header";
import Footer from "new_components/Footer/Footer";
// import buildStrategy from "new_assets/new_images/Builders/buildStrategy.png";
import buildStrategy from "new_assets/new_images/Builders/buildStrategy1.jpg";
import buildCustom from "new_assets/new_images/Builders/buildCustom.png";
import BottomBanner from "new_assets/new_images/AboutUs/banner.png";
import "new_components/Builders/Builders.scss";
import imagesss from "new_assets/new_images/Home/img-w-text-sm1.jpg";
import { BASE_URL } from "new_components/BaseApi/BaseApi.js";
import axios from "axios";
// import ComingSoon from "new_assets/new_images/Builders/coming_soon.png";
import ComingSoon from "new_assets/new_images/Builders/coming_soon1.png";
import { Image_Base_URL } from "new_components/BaseApi/BaseApi.js";
import ProgressCircle from "new_components/ScrollProgress/ScrollProgress";
import PreLoad from "new_components/PreLoad/PreLoad";
const Builders = () => {
  return (
    <>
      <ScrollToTopDefault />
      <ProgressCircle />
      <Header />
      <Body />
      <Footer />
    </>
  );
};

const Body = () => {
  return (
    <div>
      {/* <section className="flat-section">
        <div className="container">
          <div
            className="row align-items-center justify-content-center wow fadeInUp buildersbanner mx-md-5 px-1 px-md-5"
            data-wow-delay=".2s"
          >
            <div className="col-12 text-center">
              <p className="subtitle text-white body-2 wow fadeInUp">
                Welcome to
              </p>
              <h3 className="title text-white text-uppercase">
                LUCKY DUCK BUILDERS
              </h3>
            </div>
            <div className="col-12 align-items-left">
              <p className="subtitle text-white body-2 wow fadeInUp px-5">
                Lucky Duck Builders construct high-end properties in prime
                waterfront locations across Southwest Florida. We design each
                bespoke property to meet the needs of discerning buyers looking
                to add personal residences, vacation homes, or investment
                properties to their portfolios.
              </p>
              <br></br>
              <p className="subtitle text-white body-2 wow fadeInUp px-5">
                Join us and rejuvenate the Florida coastline.
              </p>
              <br></br>
              <button
                className="tf-btn btn-view primary hover-btn-view ml-5"
                onClick={() => {
                  const url = new URL("/contact-us", window.location.origin);
                  url.searchParams.set("form", "form2");
                  window.location.href = url.toString();
                }}
              >
                Contact us<span className="icon icon-arrow-right2"></span>
              </button>
            </div>
          </div>
        </div>
      </section> */}
      <BuildersBanner />
      {/* investment strategy */}
      <BuildingStrategy />
      {/* <section className="flat-section bg-primary-new">
        <div className="container">
          <div className="box-title text-center wow fadeInUp">
            <h3 className="mt-4 title">BUILDING STRATEGY</h3>
          </div>
          <div
            className="row align-items-center justify-content-center wow fadeInUp"
            data-wow-delay=".2s"
          >
            <div className="col-12 col-md-4 text-center mb-2 build-strategy">
              <img src={buildStrategy} alt="builder right" />
            </div>
            <div className="col-12 col-md-8">
              <div className="note body-2 mb-3 fw-bold">
                Dream Homes. Designed to Endure. Built for Investment.{" "}
              </div>
              <div className="note body-2 mb-3">
                The Lucky Duck Group building strategy sees dwellings as more
                than homes—but as lasting legacies of people and communities.
                Our construction philosophy emphasizes precision, quality, and
                future-proofing every detail.{" "}
              </div>
              <div className="investmentphildata note body-2">
                <ul>
                  <li>
                    <b className="investmentphildatapoint fw-bold">
                      RESILIENT CONSTRUCTION
                    </b>
                  </li>
                  <div className="investmentphildatadesc">
                    Every property is designed to work with Southwest Florida’s
                    coastal climate, featuring raised designs,
                    hurricane-resistant glass, and sea turtle friendly
                    solutions. We build beyond today—we build for the future.
                  </div>
                  <li>
                    <b className="investmentphildatapoint fw-bold">
                      PREMIUM AMENITIES
                    </b>
                  </li>
                  <div className="investmentphildatadesc">
                    Every property features a design born of thoughtful choices
                    and amenities that make the most of the location and the
                    Southwest Florida lifestyle. Homes are built with the finest
                    craftsmanship, meticulous attention to detail and materials
                    specifically curated for each project.
                  </div>
                  <li>
                    <b className="investmentphildatapoint fw-bold">
                      COST MANAGEMENT
                    </b>
                  </li>
                  <div className="investmentphildatadesc">
                    Efficient Cost Management limited vendor transitions and A
                    Strong Business Pian Are Crucial To Maximizing Returns On
                    all our projects.
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* investment strategy end */}
      {/* <div class="container lux-con">
        <div class="wrap-banner bg-primary-new">
          <div class="box-left">
            <div class="box-title">
              
              <h3 class="mt-4 mb-3 fw-8">CUSTOM CONSTRUCTION</h3>
              <div className="note body-2">
                Homes are built with the finest materials and
                craftsmanship.Features include custom cabinetry, high-end
                appliances, marble countertops, hardwood floors, and smart home
                technology.
              </div>
            </div>
            
          </div>
          <div class="box-right">
            <img src={buildCustom} alt="image" />
          </div>
        </div>
      </div> */}
      {/* dualbanner  */}
      <DualBannerBuildersRow />
      {/* <section className="row dualbannerbuildersrow">
        <div className="col-12 col-md-6 dualbannerbuildersone">
          <h6 className="title text-white">ONGOING PROJECTS</h6>
         
          <br></br>
          <button
            className="tf-btn btn-view primary hover-btn-view ml-5 dualbannerinvonebtn text-black"
            onClick={() => {
              const url = new URL("/contact-us", window.location.origin);
              url.searchParams.set("form", "form2");
              window.location.href = url.toString();
            }}
          >
            Know More
            <span className="icon icon-arrow-right2 dualbannerinvonebtnicon"></span>
          </button>
        </div>
        <div className="col-12 col-md-6 dualbannerbuilderssecpic"></div>
      </section> */}
      {/* dualbanner end */}
      {/* Ongoing construction */}

      <OngoingConstructions />

      {/* end of Ongoing construction */}

      {/* Ongoing construction */}
      <CompletedConstructions />
      {/* <section className="flat-section pb-0">
        <div className="container py-5 bg-primary-new">
          <div className=" box-title wow fadeInUp closedInv">
            <h3 className="title text-uppercase">Completed construction</h3>
          </div>
          <CompletedConstructions />
        </div>
      </section> */}
      {/* end of Ongoing construction */}

      {/* LDG Quote banner  */}
      {/* <section className="flat-section">
        <div className="container">
          <div
            className="row align-items-center justify-content-center wow fadeInUp ldgquote mx-md-5"
            data-wow-delay=".2s"
          >
            <div className="col-12 text-center">
              <h3 className="title text-white">
                Let's Build Our Future Together
              </h3>
              <div className="note body-2 text-white pt-2">
                LDG Development maintains transparent and professional
                communication with investors, providing regular updates on
                project progress and financial performance. We are a click or
                call away whenever you want to connect with us.
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <QuoteSection />
      {/* LDG Quote banner */}
    </div>
  );
};

const OngoingConstructions = () => {
  const [properties, setProperties] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null);
  const itemsPerPage = 4;

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/propertiesongoingInv`);
        if (response.data.status === "success") {
          setProperties(response.data.data);
        } else {
          setError("Failed to fetch properties.");
        }
      } catch (err) {
        setError("An error occurred while fetching data.");
      }
    };

    fetchProperties();
  }, []);

  const totalPages = Math.ceil(properties.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentProperties = properties.slice(indexOfFirstItem, indexOfLastItem);

  const nextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  return (
    <section className="completed-projects">
      <section className="flat-section pb-0">
        <div className="container py-5 bg-primary-new">
          <div className=" box-title wow fadeInUp closedInv">
            <h3 className="title text-uppercase">Ongoing construction</h3>
          </div>
          <div>
            {error ? (
              <div className="text-center coming-soon note body-2 row px-md-4">
                <div className="col">
                  <img
                    src={ComingSoon}
                    alt="Coming Soon"
                    className="w-75 float-md-start"
                  />
                </div>
                <div className="col align-content-around">
                  <h3>Coming Soon</h3>
                </div>
              </div>
            ) : currentProperties.length > 0 ? (
              currentProperties.map((property) => (
                <div key={property.id} className="row mb-5 closedprop bg-white">
                  <div className="buildercompletedprojectshero col-12 col-md-4 col-xl-2 text-center">
                    <img
                      src={Image_Base_URL + property.image}
                      alt={property.title || "Property Image"}
                      className="project-image"
                    />
                  </div>

                  <div className=" col-12 col-md-8 col-xl-10 p-md-3 pl-lg-4 pb-2">
                    <div className="row  body-2 fw-bold pb-2 pt-1">
                      <div className="col">{property.title}</div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-12 col-sm-6 col-xl-3 pb-md-2 pb-1">
                        Sq Ft Under AC:{" "}
                        <span className="fw-bold">
                          {property.sq_ft_under_ac}
                        </span>
                      </div>
                      <div className="col-12 col-sm-6 col-xl-3 pb-md-2 pb-1">
                        Sq Ft Exterior Living:{" "}
                        <span className="fw-bold">
                          {property.sq_ft_exterior_living}
                        </span>
                      </div>
                      <div className="col-12 col-sm-6 col-xl-3 pb-md-2 pb-1">
                        SqFt Garage:{" "}
                        <span className="fw-bold">{property.sq_ft_garage}</span>
                      </div>
                      <div className="col-12 col-sm-6 col-xl-3 pb-md-2 pb-1">
                        Total Property:{" "}
                        <span className="fw-bold">
                          {property.total_property}
                        </span>
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: property.description,
                        }}
                      />
                    </div>
                    <div className="row pt-1 fw-bold">
                      {property.amenities.map((amenity, index) => (
                        <div
                          key={index}
                          className=" col-12 col-lg-6 col-xl-4 pb-md-2 pb-1"
                        >
                          {amenity}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center coming-soon note body-2 row px-md-4">
                <div className="col">
                  <img
                    src={ComingSoon}
                    alt="Coming Soon"
                    className="w-75 float-md-start"
                  />
                </div>
                <div className="col align-content-around">
                  <h3>Coming Soon</h3>
                </div>
              </div>
            )}
          </div>
          {currentProperties.length > 0 && !error && totalPages > 1 && (
            <div className="pagination-controls text-center">
              <button
                className="btn"
                onClick={prevPage}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <button
                className="btn"
                onClick={nextPage}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          )}
        </div>
      </section>

      {/* {totalPages > 1 && (
        <div className="viewall">
          <a
            className="viewalllink body-2 viewalllink"
            href="/services/development/closed-investors"
          >
            View All Closed Projects
            <i className="fa-solid fa-chevron-right" aria-hidden="true"></i>
          </a>
        </div>
      )} */}
    </section>
  );
};

const CompletedConstructions = () => {
  const [properties, setProperties] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null);
  const itemsPerPage = 4;

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/propertiescompleteInv`);
        if (response.data.status === "success") {
          setProperties(response.data.data);
        } else {
          setError("Failed to fetch properties.");
        }
      } catch (err) {
        setError("An error occurred while fetching data.");
      }
    };

    fetchProperties();
  }, []);

  const totalPages = Math.ceil(properties.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentProperties = properties.slice(indexOfFirstItem, indexOfLastItem);

  const nextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };
  if (!properties.length && !error) {
    return null;
  }

  return (
    <section className="completed-projects">
      <section className="flat-section pb-0">
        <div className="container py-5 bg-primary-new">
          <div className=" box-title wow fadeInUp closedInv">
            <h3 className="title text-uppercase">Completed construction</h3>
          </div>
          <div>
            {error ? (
              <div className="text-center coming-soon note body-2 row px-md-4">
                <div className="col">
                  <img
                    src={ComingSoon}
                    alt="Coming Soon"
                    className="w-75 float-md-start"
                  />
                </div>
                <div className="col align-content-around">
                  <h3>Coming Soon</h3>
                </div>
              </div>
            ) : currentProperties.length > 0 ? (
              currentProperties.map((property) => (
                <div key={property.id} className="row mb-5 closedprop bg-white">
                  <div className="buildercompletedprojectshero col-12 col-md-4 col-xl-2 text-center">
                    <img
                      src={Image_Base_URL + property.image}
                      alt={property.title || "Property Image"}
                      className="project-image"
                    />
                  </div>

                  <div className=" col-12 col-md-8 col-xl-10 p-md-3 pl-lg-4 pb-2">
                    <div className="row  body-2 fw-bold pb-2 pt-1">
                      <div className="col">{property.title}</div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-12 col-sm-6 col-xl-3 pb-md-2 pb-1">
                        Sq Ft Under AC:{" "}
                        <span className="fw-bold">
                          {property.sq_ft_under_ac}
                        </span>
                      </div>
                      <div className="col-12 col-sm-6 col-xl-3 pb-md-2 pb-1">
                        Sq Ft Exterior Living:{" "}
                        <span className="fw-bold">
                          {property.sq_ft_exterior_living}
                        </span>
                      </div>
                      <div className="col-12 col-sm-6 col-xl-3 pb-md-2 pb-1">
                        SqFt Garage:{" "}
                        <span className="fw-bold">{property.sq_ft_garage}</span>
                      </div>
                      <div className="col-12 col-sm-6 col-xl-3 pb-md-2 pb-1">
                        Total Property:{" "}
                        <span className="fw-bold">
                          {property.total_property}
                        </span>
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: property.description,
                        }}
                      />
                    </div>
                    <div className="row pt-1 fw-bold">
                      {property.amenities.map((amenity, index) => (
                        <div
                          key={index}
                          className=" col-12 col-lg-6 col-xl-4 pb-md-2 pb-1"
                        >
                          {amenity}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center coming-soon note body-2 row px-md-4">
                <div className="col">
                  <img
                    src={ComingSoon}
                    alt="Coming Soon"
                    className="w-75 float-md-start"
                  />
                </div>
                <div className="col align-content-around">
                  <h3>Coming Soon</h3>
                </div>
              </div>
            )}
          </div>
          {currentProperties.length > 0 && !error && totalPages > 1 && (
            <div className="pagination-controls text-center">
              <button
                className="btn"
                onClick={prevPage}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <button
                className="btn"
                onClick={nextPage}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          )}
        </div>
      </section>

      {/* {totalPages > 1 && (
        <div className="viewall">
          <a
            className="viewalllink body-2 viewalllink"
            href="/services/development/closed-investors"
          >
            View All Closed Projects
            <i className="fa-solid fa-chevron-right" aria-hidden="true"></i>
          </a>
        </div>
      )} */}
    </section>
  );
};

const BuildersBanner = () => {
  const [bannerData, setBannerData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/builder-banner`);
        if (response.data.status === "success") {
          setBannerData(response.data.data[0]);
        } else {
          setError("Failed to fetch data");
        }
      } catch (err) {
        setError("An error occurred while fetching data");
      }
    };

    fetchData();
  }, []);

  if (error) {
    return <div className="text-white text-center">{error}</div>;
  }

  if (!bannerData) {
    return <div className="text-white text-center">Loading...</div>;
  }

  return (
    <section className="flat-section">
      <div className="container">
        <div
          className="row align-items-center justify-content-center wow fadeInUp buildersbanner mx-md-5 px-1 px-md-5"
          data-wow-delay=".2s"
          style={{
            backgroundImage: `url(${Image_Base_URL}${bannerData.image})`,
          }}
        >
          <div className="col-12 text-center">
            <p className="subtitle text-white body-2 wow fadeInUp">
              {bannerData.title1}
            </p>
            <h3 className="title text-white text-uppercase">
              {bannerData.title2}
            </h3>
          </div>
          <div className="col-12 align-items-left">
            <div
              className="subtitle text-white body-2 wow fadeInUp px-5"
              dangerouslySetInnerHTML={{ __html: bannerData.description }}
            ></div>
            <br />
            <button
              className="tf-btn btn-view primary hover-btn-view ml-5"
              onClick={() => {
                const url = new URL("/contact-us", window.location.origin);
                url.searchParams.set("form", "form2");
                window.location.href = url.toString();
              }}
            >
              Contact us<span className="icon icon-arrow-right2"></span>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
const BuildingStrategy = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/building-strategy`)
      .then((response) => {
        if (response.data.status === "success") {
          setData(response.data.data[0]);
        } else {
          setError("Failed to fetch data");
        }
      })
      .catch((err) => {
        setError("An error occurred while fetching data");
      });
  }, []);

  if (error) {
    return <div className="text-center text-danger">{error}</div>;
  }

  if (!data) {
    return <div className="text-center">Loading...</div>;
  }

  return (
    <section className="flat-section bg-primary-new">
      <div className="container">
        <div className="box-title text-center wow fadeInUp">
          <h3 className="mt-4 title">{data.title}</h3>
        </div>
        <div
          className="row align-items-center justify-content-center wow fadeInUp"
          data-wow-delay=".2s"
        >
          <div className="col-12 col-md-4 text-center mb-2 build-strategy">
            <img
              src={data.image ? `${Image_Base_URL}${data.image}` : "#"} // Replace "your-image-base-url" with your image base URL
              alt="builder right"
            />
          </div>
          <div className="col-12 col-md-8">
            <div
              className="note body-2 mb-3 fw-bold"
              dangerouslySetInnerHTML={{ __html: data.description }}
            ></div>
          </div>
        </div>
      </div>
    </section>
  );
};
const DualBannerBuildersRow = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/call-to-action`);
        setData(response.data.data);
      } catch (err) {
        setError("Failed to fetch data. Please try again later.");
      }
    };

    fetchData();
  }, []);

  if (error) {
    return <div className="error">{error}</div>;
  }

  if (!data) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <section className="row dualbannerbuildersrow">
      {data.map((item) => (
        <div key={item.id} className="col-12 col-md-6 dualbannerbuildersone">
          <h6 className="title text-white">{item.title}</h6>
          <br></br>
          <button
            className="tf-btn btn-view primary hover-btn-view ml-5 dualbannerinvonebtn text-black"
            onClick={() => {
              const url = new URL("/contact-us", window.location.origin);
              url.searchParams.set("form", "form2");
              window.location.href = url.toString();
            }}
          >
            {item.button_text}
            <span className="icon icon-arrow-right2 dualbannerinvonebtnicon"></span>
          </button>
        </div>
      ))}
      <div
        className="col-12 col-md-6 dualbannerbuilderssecpic"
        style={{
          backgroundImage: `url(${Image_Base_URL}${data[0].image})`,
        }}
      ></div>
    </section>
  );
};
const QuoteSection = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/bottom-banner`);
        if (
          response.data.status === "success" &&
          response.data.data.length > 0
        ) {
          setData(response.data.data[0]);
        } else {
          throw new Error(
            "Invalid API response structure or no data available."
          );
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="text-center">
        <PreLoad />
      </div>
    );
  }

  if (error) {
    return <div className="text-center text-danger">Error: {error}</div>;
  }

  return (
    <section className="flat-section">
      <div className="container">
        <div
          className="row align-items-center justify-content-center wow fadeInUp ldgquote mx-md-5"
          data-wow-delay=".2s"
          style={{ backgroundImage: `url(${Image_Base_URL}${data.bg_image})` }}
        >
          <div className="col-12 text-center">
            <h3 className="title text-white">
              {data?.title || "Title not available"}
            </h3>
            <div className="note body-2 text-white pt-2">
              {data?.footer_text || "Description not available"}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Builders;
