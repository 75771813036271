import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "new_components/BaseApi/BaseApi.js";
import { Image_Base_URL } from "new_components/BaseApi/BaseApi.js";
const InvestmentPhilosophy = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch data from API
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/philosophy`);
        const PhilosophyData = response.data?.data?.[0];
        setData(PhilosophyData);
      } catch (err) {
        setError("Failed to load data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <section className="flat-section">
      <div className="container">
        <div className="box-title text-center wow fadeInUp">
          <h3 className="mt-4 title text-uppercase">
            {data?.title || "INVESTMENT PHILOSOPHY"}
          </h3>
        </div>
        <div
          className="row align-items-center justify-content-center wow fadeInUp"
          data-wow-delay=".2s"
        >
          <div className="col-12 col-md-4 text-center mb-2">
            <img
              src={Image_Base_URL + data?.image || "default-image.jpg"}
              alt="INVESTMENT PHILOSOPHY"
            />
          </div>
          <div className="col-12 col-md-8">
            <div
              className="note body-2 mb-3"
              dangerouslySetInnerHTML={{
                __html: data?.description || "Description not available.",
              }}
            />
            {/* <div className="investmentphildata note body-2">
              <ul>
                {data?.points?.map((point, index) => (
                  <li key={index}>
                    <b className="investmentphildatapoint fw-bold">
                      {point.title}
                    </b>
                    <div className="investmentphildatadesc">
                      {point.description}
                    </div>
                  </li>
                ))}
              </ul>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default InvestmentPhilosophy;
