import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "new_components/BaseApi/BaseApi.js";
import { Image_Base_URL } from "new_components/BaseApi/BaseApi.js";

const OfferingsSection = () => {
  const [offerings, setOfferings] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/offerings`);
        const data = response.data.data;

        if (data && data.length > 0) {
          const offeringData = data[0];
          setTitle(offeringData.title);
          setDescription(offeringData.description); // HTML content
          setImage(offeringData.image);
          setOfferings(offeringData.items || []);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {/* Offerings Section */}
      <section className="flat-section bg-primary-new">
        <div className="container">
          <div className="box-title text-center wow fadeInUp">
            <h3 className="mt-4 title text-uppercase">{title}</h3>
          </div>
          <div
            className="row align-items-center justify-content-center wow fadeInUp"
            data-wow-delay=".2s"
          >
            <div className="col-12 col-md-8">
              {/* Render HTML description */}
              <div
                className="note body-2 mb-3"
                dangerouslySetInnerHTML={{ __html: description }}
              ></div>
              <div className="investmentphildata note body-2">
                <ul>
                  {offerings.map((offering, index) => (
                    <li key={index}>
                      <b className="investmentphildatapoint fw-bold">
                        {offering.title}
                      </b>
                      <div className="investmentphildatadesc">
                        {offering.description}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-12 col-md-4 text-center mb-2 market-pic">
              {image && (
                <img
                  src={`${Image_Base_URL}${image}`}
                  alt="Offerings"
                  className="img-fluid"
                />
              )}
            </div>
          </div>
        </div>
      </section>

      {/* LDG Quote Banner */}
    </>
  );
};

export default OfferingsSection;
