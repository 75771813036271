import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "new_components/BaseApi/BaseApi.js";
import { Image_Base_URL } from "new_components/BaseApi/BaseApi.js";
const MarketOpportunitySection = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/market-opportunities`);
        const marketData = response.data?.data?.[0];
        setData(marketData);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch market opportunity data.");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <section className="flat-section pt-0">
        <div className="container text-center">
          <p>Loading...</p>
        </div>
      </section>
    );
  }

  if (error) {
    return (
      <section className="flat-section pt-0">
        <div className="container text-center">
          <p className="text-danger">{error}</p>
        </div>
      </section>
    );
  }

  return (
    <section className="flat-section pt-0">
      <div className="container">
        <div className="box-title text-center wow fadeInUp">
          <h3 className="mt-4 title">{data?.title || "Market Opportunity"}</h3>
        </div>
        <div
          className="row align-items-center justify-content-center wow fadeInUp"
          data-wow-delay=".2s"
        >
          <div className="col-12 col-md-8 mb-2">
            <div
              className="note body-2 mb-3"
              dangerouslySetInnerHTML={{
                __html: data?.description || "No description available.",
              }}
            />
          </div>

          <div className="col-12 col-md-4 text-center market-pic">
            <img
              src={Image_Base_URL + data?.image || "placeholder.png"}
              alt={data?.imageAlt || "Market opportunity illustration"}
              className="img-fluid"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MarketOpportunitySection;
