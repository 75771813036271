import "App.css";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "routes";
import { Provider } from "react-redux";
import store from "store/store";
import AuthLoader from "store/authLoader";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <AuthLoader />
        <AppRoutes />
      </Router>
    </Provider>
  );
}

export default App;
